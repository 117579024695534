import React, { useState, useContext, createContext, useEffect } from "react"
import AppContext from 'AppContext'
import { useServicesContext } from "services/apiServices"
import { WorkspaceContext } from "app/commonComponents/Layout/Layout"
import { useQuery } from "react-query"

const SharepointContext=createContext(null)
const initialDrawerState = {isOpen:false,page:'create',title:''}
const initialModalState = { isOpen:false,message:'',callBack:null}
const initialState = {
    sites:[],
    refreshSites:true,
    checkedSites:[],
    searchQuery:'',
    isFetching:true,
    totalRecords:0,
    currentPage:1,
    itemsPerPage: 100,
    selectedSite:null,
    drawerState:initialDrawerState,
    modalState: initialModalState,
    hasCertificate:false
}

export const  SharepointContextProvider=({children})=>{
    const [globalSiteState, setGlobalSiteState] = useState(initialState)
    const { tenant, appData } = useContext(AppContext)
    const {apiCall,createOrUpdateAsync,triggerRefetchTotalRecords} = useServicesContext()
    const {workspaceMetadata} = useContext(WorkspaceContext)
    const top = globalSiteState.currentPage * globalSiteState.itemsPerPage
    const skip = top - globalSiteState.itemsPerPage

    const transformDate = date => {
        const utcDateString = date
        const utcDate = new Date(utcDateString)
        const localDate = new Date(utcDate.getTime())
        const options = {
          hour12: true,
          hour: 'numeric',
          minute: 'numeric',
          day: 'numeric',
          month: 'numeric',
          year: 'numeric'
        }
        return localDate.toLocaleString('en-US', options)
    }

    const getSites=()=>{
        return apiCall({
         options: {
          url: `InputFiles/getFiles`,
          method: "GET",
          variables:'sites',
          query:`$filter=(docType eq 'sharepoint_site' and crawlStatus eq 'Published')&$skip=${skip}&$top=${globalSiteState.itemsPerPage}&$orderby=updatedAt desc`
          },
       })
      }
    const { isFetching, data} = useQuery(['sites',workspaceMetadata],getSites,{enabled:globalSiteState.refreshSites})

    useEffect(()=>{
        setGlobalSiteState(prevState=>({...prevState,isFetching}))
    }, [isFetching])
    
    useEffect(()=>{
        setGlobalSiteState(prevState=>({...prevState,hasCertificate:appData.global.tenantSettings.filter(setting => setting.tenantId === tenant.id && setting.name === 'certificateAuth').length !== 0,thumbPrint:appData.global.tenantSettings.filter(setting => setting.tenantId === tenant.id && setting.name === 'certificateAuth')[0]?.properties }))
    },[])
    
    useEffect(()=>{
        if(data){
            const fileTypesFilter = data?.data.rows.map(item=>({...item,createdAt:transformDate(item.createdAt),updatedAt:transformDate(item.updatedAt),}))
            setGlobalSiteState((prev)=>({...prev,totalRecords:data?.data.count,sites:fileTypesFilter,refreshSites:false}))
        }
    }, [data])

    useEffect(()=>{
        setGlobalSiteState(prevState=>({...prevState,refreshSites:true}))
    }, [globalSiteState.currentPage, globalSiteState.itemsPerPage, workspaceMetadata])
    
    const refreshSiteList = ()=>{
    setGlobalSiteState(prevState=>({...prevState,refreshSites:true}))
    }
    const handleCheckAllSites = (event) =>{
        const isChecked = event.target.checked
        const listedSites=globalSiteState.sites.filter(e=>{return e.workspace===workspaceMetadata})
        if(isChecked) 
            setGlobalSiteState(prevState=>({...prevState,checkedSites:listedSites.map(item=>item.id)}))
        else 
            setGlobalSiteState(prevState=>({...prevState,checkedSites:prevState.checkedSites.filter(file=>!listedSites.some(item=>item.id===file))})) 
    }

    const handleCheckboxChange = (event) => {
        const site = event.target.value
        const isChecked = event.target.checked
        if (isChecked) {
          !globalSiteState.checkedSites.includes(site) && setGlobalSiteState((currentState)=>({...currentState,checkedSites:[...currentState.checkedSites, site]}))
        } else {
            setGlobalSiteState((currentState)=>({...currentState,checkedSites:currentState.checkedSites.filter((f) => f !== site)}))
        }
    }
    const updateItemsPerPage = val => setGlobalSiteState(prevState => ({ ...prevState, itemsPerPage: val }))
    
    const updateCurrentPage = val => setGlobalSiteState(prevState => ({ ...prevState, currentPage: val }))
    
    const handleEditSite = id => {
        setGlobalSiteState(prevState=>({...prevState,selectedSite:id,drawerState:{isOpen:true,page:'edit'}}))
    }
    
    const handlePreviewSite = (id) => {
        setGlobalSiteState(prevState=>({...prevState,selectedSite:id,drawerState:{isOpen:true,page:'preview',isPreview:true}}))
    }
    const handleDeleteSite = (id) => {
        setGlobalSiteState(prevState=>({...prevState,selectedSite:id,modalState:{isOpen:true,message:`Are you sure to you want to delete ${globalSiteState.sites.find(site=>site.id===id).docName}?`,callBack:()=>deleteSingleSite(id)}}))
    }

    const onCreatePublishSuccess = () => {
        setGlobalSiteState(prevState=>({...prevState,refreshSites:true,drawerState:{...prevState.drawerState,page:'success',title:'Preview',text:'Sharepoint Documents in selected sites have been added to the queue and will be published shortly. This generally takes about 30 minutes, depending on the number of sites selected and number of documents present in each site'}}))
        triggerRefetchTotalRecords()
    }
    const onCreatePublishSuccessLater = () => {
        console.log(`[SPContext],onCreatePublishSuccess called. Going to set refreshSites to true`)
        setGlobalSiteState(prevState=>({...prevState,refreshSites:true,drawerState:{...prevState.drawerState,page:'success',title:'Preview',text:'Sharepoint Documents in selected sites have been added to the queue and will be published shortly. This generally takes about 30 minutes, depending on the number of sites selected and number of documents present in each site. Please refresh the list after sometime'}}))
        triggerRefetchTotalRecords()
    }
    const onCreatePublishFailure = () =>{
        setGlobalSiteState(prevState=>({...prevState,refreshSites:false,drawerState:{...prevState.drawerState,page:'fail',title:'Preview',text:'Publishing of selected urls and/or documents failed'}}))
    }

    const onEditSiteSuccess = () =>{
         setGlobalSiteState(prevState=>({...prevState,refreshSites:true,drawerState:{...prevState.drawerState,page:'success',title:'Edit',text:'Your changes have been saved successfully'}}))
         triggerRefetchTotalRecords()
    }
    const onEditSiteFailure = (error) =>{
        setGlobalSiteState(prevState=>({...prevState,refreshSites:false,drawerState:{...prevState.drawerState,page:'fail',title:'Edit',text:`Your changes could not be saved due to ${error.message}`}}))
    }

    const onAddSiteSuccess = (previewData) => {
        setGlobalSiteState(prevState=>({...prevState,drawerState:{...prevState.drawerState,page:'preview',isPreview:false,previewData}}))
    }

    const onAddSiteFailure = (error) => {
        setGlobalSiteState(prevState => ({ ...prevState, drawerState: { ...prevState.drawerState, page: 'fail', title: 'SharePoint Integration', text: error?.response?.data?.message || error } }))
    }

    const handleBulkDeleteOpen = () => {
        setGlobalSiteState(prevState=>({...prevState,modalState:{
            isOpen:true,
            message:`Are you sure to you want to delete ${globalSiteState.checkedSites.length===1 ? globalSiteState.sites.find(site=>site.id===globalSiteState.checkedSites[0]).docName: `${globalSiteState.checkedSites.length} sites` }`,
            callBack:bulkDelete
        }}))
    }
    const openDrawer = () =>{
        setGlobalSiteState(prevState=>({...prevState,drawerState:{...prevState.drawerState,isOpen:true}}))
    }

    const closeDrawer = () =>{
        setGlobalSiteState(prevState=>({...prevState,drawerState:initialDrawerState}))
    }

    const setDrawerLoading = (message) => {
         setGlobalSiteState(prevState=>({...prevState,drawerState:{isOpen:true,page:'loading',message}}))
    }

    const closeDeleteModal = () =>{
        setGlobalSiteState(prevState=>({...prevState,selectedUrl:null,modalState:initialModalState}))
    }
    const deleteSingleSite = async (id)=>{
        deleteSiteAction([id])
    }
    const bulkDelete= ()=>{
        deleteSiteAction(globalSiteState.checkedSites)
    }

    const deleteSiteAction = async (ids) => {
        const data = {
            operation:'delete_sharepoint_site',
            ids,
            docNames:globalSiteState.sites.filter(item=>ids.includes(item.id)).map(item=>item.docName)
        }
        try{
            const response= await createOrUpdateAsync({url:'InputFiles/sharepoint',method:'PUT',variables:'deleteUrl'},data)
            if(response.code===200){
                setGlobalSiteState(prevState=>({...prevState,checkedSites:[],refreshSites:true,modalState:initialModalState}))
                triggerRefetchTotalRecords()
            }
        }
        catch(error){
            setGlobalSiteState(prevState=>({...prevState,checkedSites:[],modalState:initialModalState}))
        }
    }

    return (
        <SharepointContext.Provider value={{globalSiteState,refreshSiteList,handleCheckAllSites,handleCheckboxChange,handleEditSite, handlePreviewSite, handleDeleteSite, handleBulkDeleteOpen,updateItemsPerPage,updateCurrentPage,openDrawer,closeDrawer,closeDeleteModal,setDrawerLoading,onCreatePublishSuccess,onCreatePublishFailure,onEditSiteSuccess,onEditSiteFailure,onAddSiteSuccess,onAddSiteFailure,onCreatePublishSuccessLater}} >
            {children}
        </SharepointContext.Provider>
    )

}

export const useSharepointContext=()=>{
    return useContext(SharepointContext)
}
