
import {useEffect,useState} from 'react'
import {Box,Text, Flex,Tooltip,IconButton,Spinner} from "@chakra-ui/react"
import { useQuery,useQueryClient } from 'react-query'
import {MdMenu} from 'react-icons/md'
import {FiEdit} from 'react-icons/fi'
import {HiOutlineTrash} from 'react-icons/hi2'
import {HiOutlineArrowCircleLeft} from 'react-icons/hi'
import {MdOutlineRemoveRedEye,MdOutlineStarBorder} from 'react-icons/md'
import parse from 'html-react-parser'
import { useKnowledgeArticlesContext } from './context/KnowledgeArticlesContext'
import { useServicesContext } from "services/apiServices"
import { GoQuestion } from 'react-icons/go'

const KnowledgeArticlesDetailPage = () => {
    const { globalState, openListPage, openEditPage, openDeleteModal, updateCreateEditPageProps, updateDetailPageProps, updateArticle } = useKnowledgeArticlesContext()
    const {apiCall} = useServicesContext()
    const queryClient = useQueryClient()
    const commonProps = {borderRadius:'2px', px:'5px', py:'2px',fontSize:'11px'}
    const purpleBadgeProps={...commonProps, color:'#8463F9', bg:'#EDE8FE', border:'1px solid #8463F9'}

    const [multiStepConfig,setMultiStepConfig] = useState([])
    useEffect(()=>{splitMultiStep()},[])
    const transitionDataTemplate = (val,state) =>{
        if(state === 'removeTag'){
            let match = val.match(/<button>(.*?)<\/button>/)
            return match ? match[1] : ''
        }
        if(state === 'addTag') return `<button>${val}</button>`
        if(state === 'chunk') return val.replace(/<button.*?<\/button>/g, '')?.trim()
        return val
    }
    const splitMultiStep = () =>{
        const chunks = article?.content?.split('<hr>') ?? []
        if(chunks.length >= 2){
            const transformedChunks = chunks.map((chunk,index)=>{
                return {id:index,openState:false,content:transitionDataTemplate(chunk,'chunk'),transition:transitionDataTemplate(chunk,'removeTag')}
            })
            setMultiStepConfig(transformedChunks) 
        }
    }

    const getArticles = () => apiCall({
        options: {
            url: `InputFiles/getFiles`,
            method: "GET",
            query:`$filter=id eq ${globalState.selectedArticle}`
            },
    })
  
    const { isFetching, data,} = useQuery(['article'], ()=>getArticles(),{enabled:globalState.detailPageProps.fetchArticle})
    const article = data? data.data.rows[0].articleData: globalState.articles.find(item=> item.id === globalState.selectedArticle)?.articleData
    const articleDetail = data? data.data.rows[0]: globalState.articles.find(item=> item.id === globalState.selectedArticle)
    useEffect(()=>{
        if(data){
            updateDetailPageProps({fetchArticle:false})
            updateArticle(data.data.rows[0])
        }
    },[data])

    const handleEdit = () =>{
        updateCreateEditPageProps('detail')
        openEditPage()
    }

    const handleBack = () => {
        queryClient.removeQueries('article')
        openListPage()
    }

    const formatDate = (date) => {
        if (!date) return ''
        const options = { day: '2-digit', month: 'short', year: 'numeric', hour: 'numeric', minute: '2-digit', hour12: true }
        return new Date(date).toLocaleDateString('en-GB', options).replace(/(am|pm)/g, match => match.toUpperCase())
      }
   
    return (
        <>
          {
            isFetching ?
              ( 
                <Flex direction="column" justifyContent='center' alignItems='center' >
                    <Spinner
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='#2ac769'
                        size='xl'
                        data-testid='ka_table_fetching_articles_spinner'
                    />
                    <Text my='2' data-testid='ka_detail_fetching_article_text'>Fetching Article ...</Text>
                </Flex>
            ):
            <>
            <Flex borderBottom='1px solid #D1D5DB'width={'100%'}>
                <Flex width={'100%'}>
                    <Box>
                       <HiOutlineArrowCircleLeft cursor='pointer' size='26px' color='#4299E1' onClick={handleBack} data-testid='ka_detail_back_icon'/>
                    </Box>
                    <Flex direction={'column'} width={'100%'} mt={'-2px'} pl={'10px'}>
                          <Flex gap={2} alignItems={'center'}>
                              <Flex justifyContent={'space-between'} alignItems={'center'} width={'100%'}>
                                  <Flex gap={2} alignItems={'center'}>
                                      <Text className='responsive-title-knowledgeArticle' fontWeight='600' color={'#111827'}>{article.title}</Text>
                                      {article.scripted &&
                                      <Box padding='1px 5px' borderRadius='100px' border='1px solid #8463F9'>
                                          <Text fontSize="12px" fontWeight='500' color='#8463F9' data_testid='ka_detail_scripted'>Scripted Answer</Text>
                                      </Box>}    
                                      {article.resolution_note &&
                                       <Box padding='1px 5px' borderRadius='100px' border='1px solid #229F54'>
                                          <Text fontSize="12px" fontWeight='500' color='#229F54' data_testid='ka_detail_scripted'>Resolution Note</Text>
                                       </Box>}
                                      {article.restrictAgents &&
                                       <Box padding='1px 5px' borderRadius='100px' border='1px solid #E79B04'>
                                           <Text fontSize="12px" fontWeight='500' color='#E79B04' data_testid='ka_detail_scripted'>Agent note</Text>
                                       </Box>}   
                                  </Flex>
                                  <Flex gap={2} alignItems={'center'}> 
                                      {(article.views !==null &&  article.views !== undefined && article.views !==0) &&
                                      <Tooltip placement='bottom-start' data-testid='ka_detail_view_tooltip' label={`was referred ${article.views} times in bot conversations`} fontSize='12px' bg='#fff' color='#000' borderRadius='5px' boxShadow='0px 4px 15px 0px rgba(0, 0, 0, 0.10)'>
                                          <Flex padding='2px 5px' h='19px' gap='4px' borderRadius='2px' border='1px solid #2563EB' color='#2563EB' alignItems='center'>
                                              <MdOutlineRemoveRedEye size='15px' data-testid='ka_detail_view_icon'/>
                                              <Text as='span' fontSize='12px' fontWeight='bolder' data-testid='ka_detail_view_data'>{article.views}</Text>
                                          </Flex>
                                      </Tooltip>}      
                                      {(article.rating !==null &&  article.rating !== undefined && article.rating !==0) &&
                                      <Flex padding='2px 10px' h='19px' gap='5px' borderRadius='2px' border='1px solid #E79B04' color='#E79B04' alignItems='center'>
                                          <MdOutlineStarBorder size='12px' data-testid='ka_detail_rating_icon'/>
                                          <Text as='span' fontSize='12px' fontWeight='bolder' data-testid='ka_detail_rating_data'>{article.rating}/5</Text>
                                      </Flex>}     
                                  </Flex>
                              </Flex>
                          </Flex>
                          <Box>
                              <Flex pt={'5px'} justifyContent={'space-between'} {...((!(article?.audience?.Whitelist && article?.audience?.Whitelist?.length !== 0) && !(article?.audience?.Blacklist && article?.audience?.Blacklist?.length !== 0))?{style:{alignItems:'center'}}:{style:{alignItems:'flex-end',paddingBottom:'10px'}})}>
                                  <Box {...(!(article?.audience?.Whitelist && article?.audience?.Whitelist?.length !== 0) && !(article?.audience?.Blacklist && article?.audience?.Blacklist?.length !== 0) ? { style: {display:'flex',alignItems:'center'} } : {})} >
                                      <Text fontSize='14px' color='#A0A4A8' fontWeight={'400'} data-testid='ka_table_timestamp' {...((article?.audience?.Whitelist && article?.audience?.Whitelist?.length !== 0) || (article?.audience?.Blacklist && article?.audience?.Blacklist?.length !== 0) ? { style: {padding:'10px 0px'} } : {})}>Created at: {formatDate(articleDetail?.createdAt)} | Created by: { articleDetail?.createdBy?.split('@')[0]?.replace(/\./g, ' ')?.trim() ?? articleDetail?.createdBy} | Last updated at: {formatDate(articleDetail?.updatedAt)} | Last updated by: { articleDetail?.updatedBy?.split('@')[0]?.replace(/\./g, ' ')?.trim() ?? articleDetail?.updatedBy}</Text>
                                      <Flex gap={2} py={'5px'}>
                                          {article?.audience?.Whitelist && article?.audience?.Whitelist?.length !== 0 && <Flex flexShrink={0}><Text className='responsive-label-knowledgeArticle' fontWeight={'500'} lineHeight={'normal'} color={'#6B7280'} >Whitelist Audience :</Text></Flex>}
                                          {article?.audience?.Whitelist && article?.audience?.Whitelist?.length !== 0 && 
                                              <Flex flexWrap={'wrap'} gap={2}>
                                                  {article?.audience?.Whitelist?.map((list,index)=>{
                                                  return <Box key={index} bgColor={'#DEE8FC'} borderRadius={'5px'} p={'4px'}>
                                                      <Text className='responsive-label-knowledgeArticle' fontWeight={'500'} color={'#2563EB'} lineHeight={'normal'}>{list}</Text>
                                                  </Box>})}
                                              </Flex>}
                                          {article?.audience?.Blacklist && article?.audience?.Blacklist?.length !== 0 && <Flex flexShrink={0}><Text className='responsive-label-knowledgeArticle' fontWeight={'500'} lineHeight={'normal'} color={'#6B7280'} >Blacklist Audience :</Text></Flex>}
                                          {article?.audience?.Blacklist && article?.audience?.Blacklist?.length !== 0 && 
                                              <Flex flexWrap={'wrap'} gap={2}>
                                                  {article?.audience?.Blacklist?.map((list,index)=>{
                                                  return <Box key={index} bgColor={'#DEE8FC'} borderRadius={'5px'} p={'4px'}>
                                                      <Text className='responsive-label-knowledgeArticle' fontWeight={'500'} color={'#2563EB'}  lineHeight={'normal'}>{list}</Text>
                                                  </Box>})}
                                              </Flex>}
                                      </Flex>
                                  </Box>
                                  <Flex alignItems={'center'}>
                                      <Tooltip placement='top' label='Edit Article' data_testid='ka_detail_edit_tooltip'><IconButton aria-label="Edit Article" data_testid='ka_detail_edit_button' variant='ghost' color='#94A3B8' onClick={()=>handleEdit()} icon={<FiEdit />} fontSize='24px'></IconButton></Tooltip>
                                      <Tooltip placement='top' label='Delete Article' data_testid='ka_detail_delete_tooltip'><IconButton aria-label="Delete Article" data_testid='ka_detail_delete_button' variant='ghost' color='#94A3B8' onClick={()=>openDeleteModal(globalState.selectedArticle,article.id,article.status[0].label!=='Draft')} icon={<HiOutlineTrash/>} fontSize='24px'></IconButton></Tooltip>
                                  </Flex>
                                  </Flex> 
                          </Box>    
                      </Flex>
                  </Flex>
              </Flex>
              {article.questions && article.questions.filter((data)=>data !== "").length != 0 &&
              <Box bgColor={'#F0F6FF'} p={'20px'} borderRadius={'10px'} my={'10px'}>
                  <Text fontSize={'14px'} color={'#111827'} fontWeight={500} lineHeight={'normal'} pb={'10px'}>Question Variants:</Text>
                  <Flex direction={'column'} alignItems={'start'} gap={'10px'} justifyContent={'start'}>
                      {article.questions && article.questions.length != 0 && article.questions.filter((data)=>data !== "").map((data,index)=>{
                          return <Flex key={index} gap={'10px'} alignItems={'center'}>
                                      <GoQuestion size={'18px'} color='#94A3B8'/>
                                      <Text fontSize={'14px'} color={'#6B7280'} fontWeight={400} lineHeight={'normal'}>{data}</Text>
                                  </Flex>
                      })}            
                  </Flex>
              </Box>}
              {multiStepConfig && multiStepConfig?.length !== 0 ? 
              <Box my={'10px'} pb={'10px'} cursor={'default'}>
              {multiStepConfig.map((step,index)=>{ 
              return  <Flex key={index} data_testid='ka_detail_content' my={'10px'} bgColor={'rgba(248, 249, 253, 1)'} w={'100%'} minH={'210px'} padding={'20px'} borderRadius={'10px'} justifyContent={'space-between'} direction={'column'}>
                          <Box>
                              <Text height={'19px'} fontSize={'16px'} fontWeight={'600'} color={'rgba(17, 24, 39, 1)'}>Step {index+1}</Text>
                              <Box className='ql-container' padding={'10px 0px'} color={'rgba(107, 114, 128, 1)'}  height={'auto'}>
                                  <Box className='ql-editor' padding={0} data_testid='ka_detail_content'>{parse(step?.content)}</Box>                           
                              </Box>
                          </Box>
                          <Flex pt={'10px'} gap={2} alignItems={'center'} borderTop={'1px solid rgba(209, 213, 219, 1)'}>
                              <Text fontWeight={'500'} fontSize={'14px'} color={'rgba(17, 24, 39, 1)'}>Transition Button:</Text>
                              <Flex alignItems={'center'} justifyContent={'center'} bgColor={'rgba(255, 255, 255, 1)'} border={'1px dashed rgba(209, 213, 219, 1)'} color={'rgba(107, 114, 128, 1)'} fontSize={'14px'} fontWeight={'400'} borderRadius={'5px'} width={"80px"} height={"31px"} p={'7px 24px'}>{step?.transition}</Flex>
                          </Flex>
                      </Flex>})
              }
              </Box>
            :
            <Box className='ql-container' color={'#6B7280'} padding={'0px 0px 30px 0px'} height={'auto'} {...(!(article.questions && article.questions.filter((data)=>data !== "").length != 0) && {style:{marginTop:'10px'}})}>
                <Box className='ql-editor' padding={0} data_testid='ka_detail_content'>{parse(article.content)}</Box>                           
            </Box>}  
            </>
        } 
        </>
    )
}

export default KnowledgeArticlesDetailPage