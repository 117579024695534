import {
    Flex,Box,Text,
    Modal, ModalOverlay, ModalContent, ModalBody,Tooltip} from  "@chakra-ui/react"
import parse from 'html-react-parser'
import {MdOutlineRemoveRedEye,MdOutlineStarBorder} from 'react-icons/md'
import {IoCloseCircleOutline} from 'react-icons/io5'
import {useKnowledgeArticlesContext} from './context/KnowledgeArticlesContext'


const KnowledgeArticlesPreviewModal = () => {
    const {closePreviewModal,globalState} = useKnowledgeArticlesContext()
    const {isOpen,previewData} = globalState.previewModalState
    const commonProps = {borderRadius:'2px', px:'5px', py:'2px',fontSize:'11px'}
    const purpleBadgeProps={...commonProps, color:'#8463F9', bg:'#EDE8FE', border:'1px solid #8463F9'}
    return (
        <Modal isOpen={isOpen} onClose={closePreviewModal}  isCentered data-testid='ka_preview_container' maxH='100vh' >
            <ModalOverlay />
            <ModalContent paddingBottom='18px' h='839px' minW='600px' maxW='80vw' borderRadius='10px' boxShadow='0px 4px 15px 0px rgba(0, 0, 0, 0.10)' maxH='80vh' overflow='hidden' >
                <ModalBody tabIndex='1' data-testid='ka_preview_body' height={'100%'}>
                    <Flex direction='column' alignItems='flex-start' height={'100%'}>
                            <Flex direction='column' alignItems='flex-start' padding='21px 0px' borderBottom='1px solid #D1D5DB' gap='15px' alignSelf='stretch'>
                                <Flex justify='space-between' alignSelf='stretch'>
                                    <Flex gap='5px' alignItems='center'>
                                        <Text as='span' fontSize="20px" fontWeight='600' color='#000' data-testid='ka_preview_title'>{previewData.title}</Text>
                                        {previewData.scripted &&
                                            <Flex gap='4px' alignItems='center' justify='center' padding='2px 5px' borderRadius='100px' border='1px solid #8463F9'>
                                                <Text as='span' fontSize="12px" fontWeight='bolder' color='#8463F9' data-testid='ka_preview_scripted'>Scripted Answer</Text>
                                            </Flex>
                                        }
                                        {previewData.resolution_note &&
                                            <Flex gap='4px' alignItems='center' justify='center' padding='2px 5px' borderRadius='100px' border='1px solid #229F54'>
                                                <Text as='span' fontSize="12px" fontWeight='bolder' color='#229F54' data-testid='ka_preview_resolution'>Resolution Note</Text>
                                            </Flex>
                                        }
                                        {previewData.restrictAgents &&
                                            <Flex gap='4px' alignItems='center' justify='center' padding='2px 5px' borderRadius='100px' border='1px solid #E79B04'>
                                                <Text as='span' fontSize="12px" fontWeight='bolder' color='#E79B04' data-testid='ka_preview_scripted'>Agent note</Text>
                                            </Flex>
                                        }
                                    </Flex>
                                    <IoCloseCircleOutline cursor='pointer' size='24px' color='#94A3B8' onClick={closePreviewModal} data-testid='ka_preview_close_icon'/>
                                </Flex>                               
                                {/* ------------------------ Disable for Nov 2023 release -------------
                                 {previewData.folder &&  
                                    <Flex padding='2px 5px' h='19px' gap='4px' alignItems='center' borderRadius='2px' border='1px solid #2563EB'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                            <path d="M1.25 4.56246V11.4375H6.19625L9.63375 7.99996L6.19625 4.56246H1.25ZM2.5 5.81246H5.67875L7.86625 7.99996L5.67875 10.1875H2.5V5.81246ZM9.11625 5.18746L11.9287 7.99996L9.11625 10.8125L10 11.6962L13.6962 7.99996L10 4.30371L9.11625 5.18746Z" fill="#2563EB"/>
                                        </svg>
                                        <Text as='span' fontSize='12px' fontWeight='bolder' color='#2563EB' data-testid='ka_preview_folder'>{previewData.folder}</Text>
                                    </Flex>
                                } */}
                            </Flex>  
                            {previewData?.multiSteps && previewData?.multiSteps?.length !== 0 ? 
                                <Box my={'10px'} pb={'10px'} width={'100%'} cursor={'default'} overflowY={'auto'}>
                                    {previewData?.multiSteps?.map((step,index)=>{ 
                                        return  <Flex key={index} bgColor={'rgba(248, 249, 253, 1)'} w={'100%'} minH={'210px'} m={'10px 0px'} padding={'20px'} borderRadius={'10px'} justifyContent={'space-between'} direction={'column'}>
                                                    <Box>
                                                        <Text height={'19px'} fontSize={'16px'} fontWeight={'600'} color={'rgba(17, 24, 39, 1)'}>Step {index+1}</Text>
                                                        <Box padding={'10px 0px'} color={'rgba(107, 114, 128, 1)'} className='ql-editor ql-snow' data-testid='ka_preview_description'>
                                                            {parse(step?.content ?? '')}
                                                        </Box>
                                                    </Box>
                                                    <Flex pt={'10px'} gap={2} alignItems={'center'} borderTop={'1px solid rgba(209, 213, 219, 1)'}>
                                                        <Text fontWeight={'500'} fontSize={'14px'} color={'rgba(17, 24, 39, 1)'}>Transition Button:</Text>
                                                        <Flex alignItems={'center'} justifyContent={'center'} bgColor={'rgba(255, 255, 255, 1)'} border={'1px dashed rgba(209, 213, 219, 1)'} color={'rgba(107, 114, 128, 1)'} fontSize={'14px'} fontWeight={'400'} borderRadius={'5px'} width={"80px"} height={"31px"} p={'7px 24px'}>{step?.transition}</Flex>
                                                    </Flex>
                                                </Flex>})
                                        }
                                </Box>             
                            : 
                            <Box className='ql-container' w='100%'>
                                <Box padding='30px 0px' maxH='70vh' overflow='auto' h='658px' className='ql-editor ql-snow' data-testid='ka_preview_description'>
                                    {parse(previewData.content)}
                                </Box>
                            </Box>   
                            }
                            <Flex justify='space-between' alignSelf='stretch' paddingTop='20px'>
                                {previewData.audience?.length>0 && 
                                    <Flex gap='10px' alignItems='center'>
                                        <Text as='span' w='83px' fontSize='14px' color='#000' data-testid='ka_preview_audience_label'>Audience</Text>
                                        {previewData.audience?.map((attribute,index)=>{
                                            return (<Text key={index} as='span' {...purpleBadgeProps} data-testid={`ka_preview_audience_value_${index}`} >{attribute.key}: {attribute.values.join(', ')}</Text>)
                                        })}
                                    </Flex>
                                }                              
                                <Flex gap='10px'>
                                    {(previewData.views !==null &&  previewData.views !== undefined && previewData.views !== 0) &&
                                        <Tooltip placement='bottom-start' data-testid='ka_preview_view_tooltip' label={`was referred ${previewData.views} times in bot conversations`} fontSize='12px' bg='#fff' color='#000' borderRadius='5px' boxShadow='0px 4px 15px 0px rgba(0, 0, 0, 0.10)'>
                                            <Flex padding='2px 5px' h='19px' gap='4px' borderRadius='2px' border='1px solid #2563EB' color='#2563EB' alignItems='center'>
                                                <MdOutlineRemoveRedEye size='15px' data-testid='ka_preview_view_icon'/>
                                                <Text as='span' fontSize='12px' fontWeight='bolder' data-testid='ka_preview_view_data'>{previewData.views}</Text>
                                            </Flex>
                                        </Tooltip>
                                    }
                                    {(previewData.rating!== null && previewData.rating!== undefined && previewData.rating !== 0) &&
                                        <Flex padding='2px 10px' h='19px' gap='5px' borderRadius='2px' border='1px solid #E79B04' color='#E79B04' alignItems='center'>
                                            <MdOutlineStarBorder size='12px' data-testid='ka_preview_rating_icon'/>
                                            <Text as='span' fontSize='12px' fontWeight='bolder' data-testid='ka_preview_rating_data'>{previewData.rating}/5</Text>
                                        </Flex>
                                    }  
                                </Flex>
                            </Flex>           
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default KnowledgeArticlesPreviewModal