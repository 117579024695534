import React, { useState,useEffect,useRef } from 'react'
import { Flex,Text,Button,useDisclosure,Drawer,DrawerContent,DrawerOverlay,DrawerBody } from '@chakra-ui/react'
// import Folder from './Folder'
import {useKnowledgeArticlesContext} from './context/KnowledgeArticlesContext'
import {TbSortDescending,TbSortAscending} from 'react-icons/tb'
import {MdOutlineSearch} from 'react-icons/md'
import {VscFilter} from 'react-icons/vsc'
import {HiOutlineArrowCircleLeft} from 'react-icons/hi'
import { AiOutlineCaretRight,AiOutlineCaretDown,AiOutlineFolder } from "react-icons/ai"
  
function SaveToDrawer({openDrawer,closeDrawer,handleSubmit}) {
    const {categories} = useKnowledgeArticlesContext()
    const [folderPath, setFolderPath] = useState('')
    Object.values(categories).forEach(entry=> {
        entry.childrens = entry.childrens.map(child => child.trim())
        entry.value = entry.value.trim()
        if(entry.parent) 
            entry.parent = entry.parent.trim()
    })

    const rootCategories = categories.filter(folder=>(folder.parent === '' || folder.parent===null))

    const createFolderState = (node) =>{
        const folder = {
            id:node.id,
            expanded: false,
            selected:false,
            name:node.value.trim(),
            children: node.childrens,
            parent:(node.parent && node.parent.trim()) || null
        }
        const childrenIds = categories.filter(category => node.childrens.some(child=> child.includes(category.value)) && (category.parent!== null && category.parent.trim()===node.value.trim())).map(category=>category.id)
        const parentNode = categories.find(category => node.parent && node.parent.includes(category.value.trim()) && (category.childrens.includes(node.value.trim())))
          folder.childrenIds = childrenIds
        folder.parentId = (parentNode && parentNode.id) || null
        return {[node.id]:folder}
    }

    const buildFoldersState = (nodeArray)  =>{
        return nodeArray.reduce((prevFolderState,node)=>{
            const currentFolderState = createFolderState(node)
            let childFolderState = {}
            if (node.childrens.length > 0) {
                const childNodeArray = node.childrens.map(child => categories.find(category=> child.trim().includes(category.value.trim()) && (category.parent!== null && category.parent.trim()===node.value.trim())))
                childFolderState = buildFoldersState(childNodeArray)
            }
            return {...prevFolderState,...currentFolderState,...childFolderState}
        },{})
    }  

    const foldersObj= buildFoldersState(rootCategories)

    const [folderState,setFolderState] = useState(foldersObj)  

    // const [searchByName, setSearchByName] =  useState('')
    // const [showSearch, setShowSearch] = useState(false)
    const inputRef = useRef(null)
    const rootFolders = Object.values(folderState).filter(folder=>(folder.parent === '' || folder.parent===null))


  /**
   * 
   * Apply the set of key-value pairs for the current node and all its descendant
   */
  function doForAllDescendants(nodeId,state,childKey, kvPairArray) {
    let returnState = {...state}
    let node = returnState[nodeId]
    kvPairArray.forEach(kvpair => node = {...node, ...kvpair})
    returnState[nodeId] = node
    if(node[childKey].length >0){      
        node[childKey].forEach(child=> {
            returnState = doForAllDescendants(child,returnState,childKey,kvPairArray)
        })
    }
    return returnState
}
// /**
//  * 
//  * Apply the set of key-value pairs for the current node and all its ancestors
//  */
// function doForAllAncestors(nodeId,state,parentKey, kvPairArray) {
//     let returnState = {...state}
//     let node = returnState[nodeId]
//     kvPairArray.forEach(kvpair => node = {...node, ...kvpair})
    
//     returnState[nodeId] = node
//     if(node[parentKey]){
//         returnState = doForAllAncestors(node[parentKey],returnState,parentKey,kvPairArray)
//     }
//      return returnState 
// }

const calculatePath = (id,path) => {
    let result = ''
    if(path!== '')
        result = folderState[id].name + ' > '+path
    else
        result = folderState[id].name

    if(folderState[id].parentId)
        result = calculatePath(folderState[id].parentId,result)

    return result
}
/**
 * 
 * Expand or Collapse folders 
 */
const handleToggleExpanded = (id) => {
    setFolderPath(calculatePath(id,''))
    let newState = {...folderState}
    Object.entries(newState).forEach(([key,value]) =>{
      if(value.id===id && !value.selected)
        newState[key]={...value,selected:true}
      else if(value.id!== id && value.selected)
        newState[key]={...value,selected:false}
    })
    const node = newState[id]

    //If collapsing , collapse all children
    if(node.expanded)
        newState = doForAllDescendants(id, newState,'childrenIds',[{expanded:false}])
    //If expanding, collapse all siblings
    else{
      if(node.parent){
        const parentNode = newState[node.parentId]
        parentNode.childrenIds.forEach(siblingId =>{
            // const siblingNode = Object.entries(newState).find(([key,value])=> value.id===sibling)
            // const siblingNode = newState[siblingId]
            if(siblingId === id)
                newState[id] = {...newState[id],expanded:true}
            else{
                newState = doForAllDescendants(siblingId, newState,'childrenIds',[{expanded:false}])
            }
        })
      }else{ 
        Object.entries(newState)
        .filter(([key,value])=> {
          return value.parent === null
        })
        .forEach(([key,value])=>{
          if(value.id === id)
                newState[key] = {...newState[key],expanded:true}
            else{
                newState = doForAllDescendants(value.id, newState,'childrenIds',[{expanded:false}])
            }
        })
      }
    }
    setFolderState(newState)
}

const canSubmit = () => {
    return Object.values(folderState).some(folder=>folder.selected ===true)
}
            
    return (
             <Drawer isOpen={openDrawer} placement="right" onClose={closeDrawer} size="md" >
                <DrawerOverlay />
                <DrawerContent maxW='688px' padding='7px 17px'>
                    <DrawerBody p='0'>
                    <Flex direction='column' fontSize='14px' >
                        <Flex w='654px' pb='20px' gap='5px' alignItems='center'>
                            <HiOutlineArrowCircleLeft cursor='pointer' size='26px' color='#1E90FF' onClick={closeDrawer}  data-testid='ka_save_back_icon'/>
                            <Text as='span' fontSize='16px' fontWeight='bolder' color='#000' data-testid='ka_save_heading'>Save To</Text>
                            {/* <Flex justifyContent='flex-end' alignItems='center' gap='7px'>
                                { showSearch ?
                                    <InputGroup size='xs'>
                                        <Input placeholder="Search By Name" value={searchByName}
                                            onChange={e=> setSearchByName(e.target.value)}
                                            onKeyDown={e=>{if(e.key === 'Enter') setShowSearch(false)}}
                                            onBlur={()=>setShowSearch(false)}
                                            ref={inputRef}
                                        />
                                        <InputRightElement pointerEvents="none" children={<MdOutlineSearch />} />
                                    </InputGroup>
                                    :
                                    <React.Fragment>
                                        {sortElement}
                                        <Tooltip placement='top' label='Search folders by name'>
                                            <Box as='span'>
                                                <VscFilter cursor='pointer' size='24px' color='#94A3B8' onClick={()=>setShowSearch(true)}/>
                                            </Box>
                                        </Tooltip>
                                    </React.Fragment>
                                }
                            </Flex> */}
                        </Flex>
                        <Flex direction='column' minH='603px' gap='10px' pt='9px' data-testid='ka_save_folders_container'>
                            {
                                rootFolders.map((folder) =>
                                <Folder key={folder.id} folder={folder} isSubFolder={false} folderState={folderState} handleToggleExpanded={handleToggleExpanded}/>
                                )
                            }
                        </Flex>
                        <Flex justify='flex-end' gap='10px' alignSelf='stretch' p='10px'>
                            <Button bg="transparent" padding='0px 28px' height='36px' variant='outline' color="#6B7280"  fontSize='14px' onClick={closeDrawer} data-testid='ka_save_cancel_button'  >Cancel</Button>  
                            <Button bg="#2563EB" padding='0px 28px' height='36px' color="#fff" fontSize='14px' isDisabled={!canSubmit()} onClick={()=>handleSubmit(folderPath)} data-testid='ka_save_submit_button' >Save</Button> 
                        </Flex>
                    </Flex>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
           
    )
}

function Folder({folder,isSubFolder,folderState,handleToggleExpanded }) {
    const toggleExpand = (event) => {
      event.stopPropagation()
      handleToggleExpanded(folder.id)
    }
   
    return (
      <Flex direction='column' alignSelf='stretch' px={folder.parent ?'0px':'8px'}>
          <Flex flex='1' alignSelf='stretch' alignItems="center" cursor="pointer" onClick={toggleExpand} gap='4px'>
            {isSubFolder && 
                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="28" viewBox="0 0 16 28" fill="none">
                    <path d="M8 0L8 7.99988C8 12.4182 11.5817 15.9999 16 15.9999V15.9999" stroke="#E4E7EC"/>
                </svg>    
              } 
        
            {folder.expanded ? <AiOutlineCaretDown style={{height:'24px',width:'16px'}} color='#98A2B3'/> : <AiOutlineCaretRight style={{height:'24px',width:'16px'}} color='#98A2B3'/>}
            <AiOutlineFolder style={{ marginRight: '8px',color:folder.selected?'#2563EB':'#98A2B3' ,size:'16px'}} /> 
            {/* <Checkbox 
              _checked={{ "& .chakra-checkbox__control": { background: "#4F46E5",border:"none" }}}
              _disabled={{ "& .chakra-checkbox__control": {opacity:0.2 }}} me='10px' 
              isChecked={folderState[name].checked} onChange={toggleCheck} /> */}
            <Text flexGrow='1' color={folder.selected?'#2563EB':'inherit'} data-testid={`ka_save_folder_${folder.id}_name`}>{folder.name}</Text>
            {/* 
            <BiDotsVerticalRounded style={{ justifySelf: 'flex-end',color:'#98A2B3',size:'16px' }} />  */}
          </Flex>
          {(folder.expanded && folder.children.length >0) && (
          <Flex direction='column' borderLeft='1px solid #E4E7EC' ml={folder.parent ?'28px':'8px'} mt={1} gap='10px'> 
              {folder.childrenIds.map((childId, index) => {
                const subFolderNode = folderState[childId]
                return(
                <Folder key={index} folder={subFolderNode} isSubFolder={true} folderState={folderState} handleToggleExpanded={handleToggleExpanded}/>
              )})}
          </Flex>
          )}
      </Flex>
    )
    
  }
export default SaveToDrawer