import React, { useState,useContext,useCallback,forwardRef,useEffect,useRef} from "react"
import {Box,Text, Flex,Stack, HStack, InputGroup, Input,InputRightElement,Button,Tooltip,Switch,useToast,useColorMode,Spinner,Select ,VStack} from "@chakra-ui/react"
import {FiEdit} from 'react-icons/fi'
import {MdMenu} from 'react-icons/md'
import {FaWandMagicSparkles} from 'react-icons/fa6'
import {AiOutlinePlusCircle} from 'react-icons/ai'
import {HiOutlineInformationCircle,HiOutlineArrowCircleLeft} from 'react-icons/hi'
import { RxMagicWand , RxText,RxCalendar} from "react-icons/rx"
import { RiCloseCircleFill } from 'react-icons/ri'
import { GoAlert } from "react-icons/go"
import { SmallCloseIcon,DeleteIcon } from '@chakra-ui/icons'
import axios from 'axios'
import { useMutation,useQueryClient } from 'react-query'
import parse from 'html-react-parser'
import turndown from 'turndown'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { v4 as uuidv4 } from 'uuid'
import back from '../../../assets/back.png'
import AppContext from 'AppContext'
import TextEditor from '../../../common/components/RichTextEditor'
import { ServiceHelper } from "../../../common/helpers/ServiceHelper"
import MultiSelect from "./utils/MultiSelect"
import {useKnowledgeArticlesContext} from './context/KnowledgeArticlesContext'
import SaveToDrawer from "./SaveToDrawer"
import { WorkspaceContext } from "app/commonComponents/Layout/Layout"
import { useServicesContext } from "services/apiServices"
import { useQuery } from "react-query"
import config from '../../../config'
import TurndownService from "turndown"
import { BiError } from "react-icons/bi"
import { BsExclamationTriangle } from "react-icons/bs"
import {MdKeyboardArrowDown, MdOutlineAdd} from 'react-icons/md'
import AutoComplete from "./AutoComplete"

const toolbar_with_media =  [
    ['bold','italic','underline'], 
    ['undo','redo'], 
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }], [{ indent: '+1' }],
    [{ align: [] }],
    [{ size: ['small', false, 'large', 'huge'] }] , 
    [{ font: [] }] ,
    ['link','image','video','attachment'],
] 
// const toolbar_without_media =  [
//     ['bold','italic','underline'], 
//     ['undo','redo'], 
//     [{ list: 'ordered' }, { list: 'bullet' }],
//     [{ indent: '-1' }], [{ indent: '+1' }],
//     [{ align: [] }],
//     [{ size: ['small', false, 'large', 'huge'] }] , 
//     [{ font: [] }] ,
//     ['link'],
// ] 

/**  -------------- Convert Html string into string of words ------------------- */
const extractWordsFromHtml = (htmlString) => {
    const textContent = extractText(new DOMParser().parseFromString(htmlString, 'text/html').body).trim()
    const words = textContent.split(/[\s\t]+/).slice(0, 80)
    return words.join(' ')
}

const extractWordsFromHtmlForAutocomplete = (htmlString) => {
    const textContent = extractText(new DOMParser().parseFromString(htmlString, 'text/html').body).trim()
    const words = textContent.split(/[\s\t]+/)
    return words.join(' ')
}
  /** --------------Function to recursively extract text content with spaces ------------*/
const extractText = (node) => {
    let text = ''

    if (node.nodeType === Node.TEXT_NODE) 
    text += node.textContent.trim() + ' '
    
    else {
        for (const childNode of node.childNodes) 
            text += extractText(childNode)
        
        if (node.nodeType === Node.ELEMENT_NODE) 
            text += ' ' 
    }

    return text
  }

const KnowledgeArticlesCreatePage = (props) => {
    let initialInputState = {
        title:props.title||'',
        description:'',
        content:'',
        scripted:false,
        audience:[],
        status:[{label: 'Draft',value :'false'}],
        restrictAgents:false,
        restrictTeamSpaces: false,
        embeddedToOriginalVideoUrlMap:[]
       }
    const { appData, identity, cdnUri } = useContext(AppContext)
    const { autoComplete,audiencesRecords } = useServicesContext()
    const { workspaceMetadata } = useContext(WorkspaceContext) 
    const toast = useToast()
    const { colorMode, toggleColorMode } = useColorMode()
    const queryClient = useQueryClient()

    const tenantId = identity.profile.tenantUid
    const tenantMediaSettings = appData?.global?.tenantSettings.find(item => item.hasOwnProperty('name') && item.name === 'ai_mediaContent')?.properties

    const {articles,page,title, editorStyle,display,selectedArticle,audienceAttributes,handleBack,openPreviewModal,onSubmitSuccess,onSubmitFailure,parent} = props
    
    const articleToEdit = page === 'edit' ? articles.find(item=> item.id === selectedArticle)?.articleData : null

    if (page === 'edit'){
        initialInputState = {
            title:articleToEdit.title,
            description:articleToEdit.description,
            content: articleToEdit.content.replace(/\n/g, '<br>'),
            // content: articleToEdit.content,
            scripted:articleToEdit.scripted,
            audience: articleToEdit.audience,
            status:articleToEdit.status,
            restrictAgents:articleToEdit.restrictAgents,
            restrictTeamSpaces: articleToEdit.restrictTeamSpaces,
            embeddedToOriginalVideoUrlMap:articleToEdit.embeddedToOriginalVideoUrlMap||[]
        }
    }

    const {apiCall:callBackend} = useServicesContext()
    /** ---------------- State variables --------------- */
    const [formInputs, setFormInputs] = useState(initialInputState)
    const [formInputErrors, setFormInputErrors] = useState({})
    const linkOfUploadFile = useRef('')
    const [errOnUpload, setErrOnUpload] = useState("")
    const [showFooterInEditor, setShowFooterInEditor] = useState(extractWordsFromHtml(formInputs.content).length>9 && autoComplete)
    const [showInfo, setShowInfo] = useState(false)
    const [teamSpaces, setTeamSpaces] = useState(['IT-Service Team /Software/Apps','IT-Service Team /Software/OS'])
    // const[autoComplete,setAutoComplete]=useState(false)
    const [wordCount,setWordCount] = useState(extractWordsFromHtml(formInputs.content).length)
    const [openDrawer,setOpenDrawer] = useState(false)
    const [audienceKey, setAudienceKey] = useState(null)
    const [showTitleError, setShowTitleError] = useState(false)
    const [mediaContentError, setMediaContentError] = useState('')
    const [listKey,setListKey] = useState('')
    const [audienceValue,setAudienceValue] = useState('')
    const [audienceData, setAudienceData] = useState([])
    const [audienceOptions,setAudienceOptions] = useState(audiencesRecords|| [])
    const [questions, setQuestions] = useState(articles.find(item=> item.id === selectedArticle)?.articleData?.questions || [''])
    const [questionErrHandler , setQuestionErrHandler] = useState(null)
    // const [toolbarModules, setToolbarModules] = useState(toolbar_without_media)
    // const [toolbarModules, setToolbarModules] = useState(toolbar_with_media)
    // const editor_key = useRef('without_media')
    const [audienceError,setAudienceError] = useState('')
    const [autoCompleteDrawerSettings,setAutoCompleteDrawerSettings] = useState(false)
    const [multiStepConfig,setMultiStepConfig]=useState([])
    const [multiStepError,setMultistepError] = useState({key:null,value:null})
    const [backupdescriptions,setBackupDescription] = useState(initialInputState)
    
    const mediaIcons = ['ql-image', 'ql-video', 'ql-attachment']

    /** ------------------Conversion of html to markdown--------------- */  
    const turndownService = new turndown()
    turndownService.addRule('strikethrough', {
        filter: ['del', 's', 'strike'],
        replacement: function (content) {
            return '~~' + content + '~~'
        },
    })
    turndownService.addRule('sizeFormattingOrdered', {
        filter: function (node) {
          return (
            node.nodeName === 'SPAN' &&
            (node.classList.contains('ql-size-small') ||
              node.classList.contains('ql-size-large') ||
              node.classList.contains('ql-size-huge'))
          )
        },
        replacement: function (content, node) {
          if (node.classList.contains('ql-size-huge')) {
            return content + '\n===========\n\n' 
          } else if (node.classList.contains('ql-size-large')) {
            return content + '\n-----------\n\n' 
          } else if (node.classList.contains('ql-size-small')) {
            return '### ' + content + '\n\n' 
          }
          return content 
        },
      })
      
      turndownService.addRule('normalParagraphOrdered', {
        filter: function (node) {
          return (
            node.nodeName === 'P' &&
            !node.querySelector('span[class*="ql-size"]') 
          )
        },
        replacement: function (content) {
          return content + '\n\n'
        },
      })
    turndownService.addRule('h5', {
        filter: ['h3', 'h3', 'smallheading'],
        replacement: function (content) {
            return '##### ' + content
        },
    })
    turndownService.keep( ['video','iframe'])
    const levelArray = []
    turndownService.addRule('bulletDetector', {
            filter: 'li',
        replacement: function (content, node) {
            let indent_level = 0
            //Get the indent level of this li element
            node.classList.forEach(name => { 
                if (name.startsWith('ql-indent-'))
                    indent_level = parseInt(name.replace('ql-indent-',''))
            })
            // Check if levelArray has indent_level +1 number of elements. If not, add that many elements to level Array and set their value to 1, to indicate starting numbering from 1.
            if (levelArray.length <= indent_level) {
                const len = levelArray.length
                for (let i = len; i <= indent_level; i++)
                    levelArray.push(1)
            }
            //If the current li is a first level list i.e. it doesn't have a ql-indent class name, and it's the first child then reset levelArray[0] to 1 to restart numbering from 1
            if (indent_level == 0 && !node.previousSibling)
                levelArray[0] = 1

            const bulletType = node.parentNode.nodeName === 'UL' ? '-' : levelArray[indent_level] + '.'
            //Increment the levelArray at this indent level index to continue numbers for any subsequent li element at the same level from the current number.
            levelArray[indent_level] += 1
             //Reset numbering of all higher indent level to 1, to restart numbering of any nested lists under this li from 1. 
            for (let i = indent_level + 1; i < levelArray.length; i++){
                levelArray[i] = 1
            }
            return '\t'.repeat(indent_level) + bulletType + ' ' + content.trim()+'\n'
 
        }
    })
    let docUniqueName

    const CustomDateInput = forwardRef(({ value, onClick }, ref) => (
        <Flex p='9px 13px' border='1px solid #D1D5DB' w='830px' onClick={onClick} ref={ref} justify='space-between' alignItems='center'>
          <Text fontSize='14px' color='#000'>{value}</Text>
          <RxCalendar size='24px' color='#94A3B8'/>
        </Flex>
      ))

    const customEditorStyles = editorStyle || {
        boxheight:'446px',
        height:'386px',
        width:'830px'
    }

    /** ---------------------Event Handlers ------------------------*/

    /** -------------------- On Typing in Title, or toggling switches ---------------- */
    const handleInputChange = (e) => {
        if(questionErrHandler) setQuestionErrHandler(null) 
        const newInputs = { ...formInputs }
        if(e.target.name==='restrictAgents' && newInputs.restrictAgents === false )
           newInputs.audience=[]

        if(e.target.name==='scripted' || e.target.name==='restrictAgents'||e.target.name==='restrictTeamSpaces' )
            newInputs[e.target.name] = !newInputs[e.target.name]
        else
            newInputs[e.target.name] = e.target.value
        setFormInputs(newInputs)

        if(e.target.name in formInputErrors && formInputErrors[e.target.name]!=='' )
            setFormInputErrors({...formInputErrors,[e.target.name]:''})

        if(e.target.name==='title'){
            if(e.target.value.length>255){
                setFormInputErrors({...formInputErrors,title:'Article title must not exceed 255 characters'})
                setShowTitleError(true)
            }
            if((page==='create' && articles.find(article=>article?.articleData?.title.trim()===e.target.value.trim()))
               ||
               (page==='edit' && articles.find(article=>article?.articleData?.title.trim()===e.target.value.trim()&& article.id !== selectedArticle))){
                setFormInputErrors({...formInputErrors,title:'Another knowledge article with same subject exists'})
                setShowTitleError(true)
            }
        }
    }
    useEffect(() => { 
        if(!formInputs.scripted && multiStepConfig.length !== 0) {
            const transformedChunks = multiStepConfig.map((chunk,index)=>{
                return [chunk?.content]
            }).join('\n')
            onEditorChange(transformedChunks)
        }
        mediaIcons.forEach(iconClass => {
            const iconElements = document.querySelectorAll(`.ql-toolbar .${iconClass}`)
            iconElements.forEach(icon => {
                if (formInputs.scripted) {
                    icon.classList.remove('ql-disabled')
                } else {
                    icon.classList.add('ql-disabled')
                }
            })
        })
        if (formInputs.scripted) {
            if (mediaContentError !== '')
                setMediaContentError('')
        } else
            removeMediaContent()
        linkOfUploadFile.current = ''
    }, [formInputs.scripted])
    
    useEffect(() => { 
        mediaIcons.forEach(iconClass => {
            const iconElements = document.querySelectorAll(`.ql-toolbar .${iconClass}`)
            iconElements.forEach(icon => {
                if (formInputs.scripted) {
                    icon.classList.remove('ql-disabled')
                } else {
                    icon.classList.add('ql-disabled')
                }
            })
            
        })
    },[])

    /** ------------- On selecting a date in one of the date input fiels -------------- */
    const handleDateChange = (field,date) => {
        setFormInputs({...formInputs,[field]:date})
    } 

    /** -------------- On typing in Rich Text Editor ---------- */
    const onEditorChange = useCallback((val) => {
        // const sanitizedHtml = sanitizeQuillHtml(val)
        const regex = /<(img|video|iframe)[^>]*>/gi
        if (mediaContentError.length > 0 && !regex.test(val))
            setMediaContentError('')
        const distinctWords = extractWordsFromHtml(val)
        const words=distinctWords.split(/\s+/).filter(Boolean).length > 9
        if(words && autoComplete )
            setShowFooterInEditor(true)
        else if(!words && !autoComplete )
            setShowFooterInEditor(false)
        else setShowFooterInEditor(false)
        setFormInputs(prev =>{
            return {...prev,content: val,description:distinctWords,embeddedToOriginalVideoUrlMap:prev.embeddedToOriginalVideoUrlMap.filter(mapping=>val.includes(mapping[0]))}
        })
        setWordCount(distinctWords.length)
    }, [showFooterInEditor,autoComplete,mediaContentError])

    const  onEditorChangeForMultiSteps = useCallback((val) => {
        const distinctWords = extractWordsFromHtml(val)
        setBackupDescription(prev =>{
            return {...prev,...formInputs,content: val,description:distinctWords,embeddedToOriginalVideoUrlMap:prev.embeddedToOriginalVideoUrlMap.filter(mapping=>val.includes(mapping[0]))}
        })
    }, [])

    /** ----------On selecting any of the select drop-down options ---------- */
    const handleMultiSelectChange = (value,name) =>{
        if(name==='status')
            setFormInputs({...formInputs,status:value})
        else if(name==='audienceKey'){
            setAudienceKey(value)
            setAudienceValue('')
        }
        else
            setAudienceValue(value)
    }

    /** -------------------- On clicking the + button in the Audience field --------------  */
    const addAudienceAttribute = () => {
        const audienceAttr = audienceAttributes.find(attr=>attr.key===audienceKey[0].label)
        let newAudienceInputData = [...formInputs.audience]
        const audienceInput = newAudienceInputData.find(attr=> attr.key===audienceKey[0].label)

     
        if(!audienceInput){
            if(Array.isArray(audienceValue)) newAudienceInputData.push({key:audienceKey[0].label,values:[audienceValue[0].label]})
            else newAudienceInputData.push({key:audienceKey[0].label,values:[audienceValue]})
        }
        if(audienceInput && audienceAttr.isMultivalue==='Yes'){
            if(audienceAttr.validationRule.field==='Free Text'){
                if(audienceInput && !audienceInput.values.includes(audienceValue[0].label))
                audienceInput.values.push(audienceValue)
            }
            else{
                if(audienceInput && !audienceInput.values.includes(audienceValue[0].label))
                audienceInput.values.push(audienceValue[0].label)
            }
        } else if(audienceInput){
            if(audienceAttr.validationRule.field==='Free Text') audienceInput.values=[audienceValue]
            else audienceInput.values=[audienceValue[0].label]
        }
        setFormInputs({...formInputs,audience:newAudienceInputData})
        setAudienceKey([])
        setAudienceValue('')
    }

    /**  ------------------ On clicking the x button next to an audience attribute --------------- */
    const removeAudienceAttribute = (key,index) => {
        
        let newAudienceInputData = [...formInputs.audience]
        const audienceAttrIndexToRemove = newAudienceInputData.findIndex(attr=>attr.key===key)
        if(newAudienceInputData[audienceAttrIndexToRemove].values.length===1)
            newAudienceInputData.splice(audienceAttrIndexToRemove,1)
        else{
            newAudienceInputData[audienceAttrIndexToRemove].values.splice(index,1)
        }
        setFormInputs({...formInputs,audience:newAudienceInputData})
    }

    /** ------------- On Clicking Save To Button (This feature will be disabled for Nov 2023 release)-------------- */
    const handleSaveTo = e => {
        e.preventDefault()
        setOpenDrawer(true)
    }

    /** ------------------ Submit form inputs to call backend to create article. Replace this with commented out function below once Save to Folder feature is in scope  ------------------------- */
    const  validateDuplicates = (arr) => {
        let processedArr = arr.map((item) => item.trim().toLowerCase())
        let uniqueSet = new Set()
        for (let item of processedArr) {
          if (uniqueSet.has(item)) {
            return true
          }
          uniqueSet.add(item)
        }
        return false
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setOpenDrawer(false)
        const filterAudienceRecords = (records, audience) => {
            if(audience && records){
             const { Whitelist, Blacklist } = audience
             if (Whitelist && Whitelist.length > 0) {
               const data= records
                 .filter((record) => Whitelist.includes(record.name))
                 .map((record) => record.id)
                 return {blacklisted:false,audience:data}
             }
             if (Blacklist && Blacklist.length > 0) {
               const data= records
                 .filter((record) => Blacklist.includes(record.name))
                 .map((record) => record.id)
                 return {blacklisted:true,audience:data}
             }
             else {return {}}
            }
            else return {}
       }
       let result={}
       result = filterAudienceRecords(audiencesRecords, audienceData)
       const updatedResult=Object.keys(result).length>0?result:{audience:["all"],blacklisted:false}
        const urlBody ={}
        // const audienceString = transformAudienceData()
        const pineconeContent = getPineconeContent( multiStepConfig.length !== 0 ? backupdescriptions?.content : formInputs?.content) 
        const manipulateQues = questions.map((item)=> item.trim()).filter((item) =>{return item !== ''})
        if (questions && questions.length !== 0 && validateDuplicates(questions)) {
            setQuestionErrHandler({ errorMsg: (<Flex alignItems={"center"} gap={2}>
            <BsExclamationTriangle size={"24px"} color="#CF3626"/>
            <div style={{fontSize: "14px",fontWeight: 500,lineHeight: "20px",color: "#CF3626",}}>
            Questions need to be unique.
            </div>
          </Flex>)})
          return
        } 
        if(!(questions.length === 1) ){
            if(manipulateQues.length !== questions.length){
                setQuestionErrHandler({ errorMsg: (<Flex alignItems={"center"} gap={2}>
                    <BsExclamationTriangle size={"24px"} color="#CF3626"/>
                    <div style={{fontSize: "14px",fontWeight: 500,lineHeight: "20px", color: "#CF3626",}}>
                    Fields should not be empty.
                    </div>
                </Flex>)})
            }
        }
        let finalQues = questions
        if(questions && questions.length >= 2 ) {
            finalQues = manipulateQues
            setQuestions(manipulateQues) 
        }
        let titleQuesValidatior = questions.map((item)=>{return item.toLowerCase()})
        if(formInputs.title.toLowerCase() && titleQuesValidatior.includes(formInputs.title.toLowerCase())){
            setQuestionErrHandler({ errorMsg: (<Flex alignItems={"center"} gap={2}>
                <BsExclamationTriangle size={"24px"} color="#CF3626"/>
                <div style={{fontSize: "14px",fontWeight: 500,lineHeight: "20px",color: "#CF3626"}}>
                 "{formInputs.title}"&nbsp;Already used as title.
                </div>
              </Flex>)
             })
            return
        }
        if(page==='create'){
            urlBody.operation='create_KnowledgeArticle'
            urlBody.pineconeData = {audience:updatedResult?.audience,content:pineconeContent,blacklisted:updatedResult?.blacklisted}
            urlBody.dbData={...formInputs,questions: formInputs?.scripted ? finalQues : [''],resolution_note:false,views:0,rating:0,id:uuidv4(),audience:audienceData,
                ...(multiStepConfig.length !== 0 && formInputs.scripted)?{content:backupdescriptions.content,description:backupdescriptions.description,embeddedToOriginalVideoUrlMap:backupdescriptions.embeddedToOriginalVideoUrlMap}:{}
            }
            urlBody.workspace=workspaceMetadata                  
        }  
        else{
            urlBody.operation='update_KnowledgeArticle'
            urlBody.callDeletePinecone = callDeletePinecone()
            urlBody.id=selectedArticle
            urlBody.pineconeData = {audience:updatedResult?.audience,content:pineconeContent,blacklisted:updatedResult?.blacklisted}
            urlBody.dbData={...articleToEdit,...formInputs,questions: formInputs?.scripted ? finalQues : [''],audience:audienceData,
                ...(multiStepConfig.length !== 0 && formInputs.scripted)?{content:backupdescriptions.content,description:backupdescriptions.description,embeddedToOriginalVideoUrlMap:backupdescriptions.embeddedToOriginalVideoUrlMap}:{}
            }
            urlBody.workspace=workspaceMetadata  
            urlBody.editArticle=true
        }
        let istransitionChecked = await transitionChecker(true)
        if(!istransitionChecked){
           validateQuestionsAndCallKnowledgeArticle({data:{id:urlBody?.dbData?.id ?? null,questions:formInputs?.scripted ? manipulateQues : [],urlBody}})
        }
    }

    const mutation = useMutation(
        callBackend,
        {
            onSuccess:(data,variables)=>{ 
                if(variables?.payload?.data){
                    if(data?.message){
                        setQuestionErrHandler({ errorMsg: (<Flex alignItems={"center"} gap={2}>
                            <BsExclamationTriangle size={"24px"} color="#CF3626"/>
                            <div style={{fontSize: "14px",fontWeight: 500,lineHeight: "20px",color: "#CF3626"}}>
                            "{data?.elementCatcher}" is associated with {data?.articleTitle} article.
                            </div>
                        </Flex>)
                        }) 
                        return
                    }else{
                        callKnowledgeArticlePostAPI(variables?.payload?.data?.urlBody)
                    }
                }else{
                    queryClient.invalidateQueries('articles')
                    onSubmitSuccess(variables)
                    // parent==='gap' && onFixUploadGap(selectedGapItem,'Knowledge article')
                }
            },
            onError: (err,variables) => {
                if(!variables?.payload?.data){
                    toast({
                        title: 'Error',
                        description: variables.payload.operation==='create_KnowledgeArticle'?'Creation of Knowledge Article failed':'Knowledge Article could not be updated',
                        status: 'error',
                        duration: 5000,
                        position: 'top-right',
                        isClosable: true,
                    }) 
                    onSubmitFailure()
                }
             }
        }
    )

    const callKnowledgeArticlePostAPI = async (urlBody,headers={}) => {
        mutation.mutate({
            options: {
            url: `InputFiles/knowledgeArticles`,
            method: "POST",
            headers
            },
            payload: urlBody,
        })
    }

    const validateQuestionsAndCallKnowledgeArticle = async (urlBody,headers={}) => {  
        mutation.mutate({
            options: {
            url: `InputFiles/validateQuestions`,
            method: "POST",
            headers
            },
            payload: urlBody,
        })
    }
    /** ----------------------- Commenting out for Nov 2023 release. Replace above function with below, once Save to Folder feature is in scope --------------------------- */
    // const handleSubmit = folder => {
    //     setOpenDrawer(false)
    //     const inputErrors = checkInputErrors()
    //     if(Object.values(inputErrors).every(value=>value==='')){
    //         const urlBody ={}
    //         if(page==='create'){
    //             urlBody.operation='create_KnowledgeArticle'
    //             urlBody.payload={...formInputs,views:0,rating:0,id:uuidv4()}                
    //         }  
    //         else{
    //             urlBody.operation='update_KnowledgeArticle'
    //             urlBody.id=globalState.selectedArticle
    //             urlBody.payload={...articleToEdit,...formInputs}
    //         }
    //         if(folder)
    //             urlBody.payload.folder=folder
    //         callKnowledgeArticlePostAPI(urlBody,false)
    //     }else
    //         setFormInputErrors(state=>({...state,...inputErrors}))
    // }
    /** ------------------ On Clicking Preview button ---------------------  */
    const openPreview = () =>{
        let previewData = {
            title:formInputs.title,
            content:formInputs.content,
            scripted:formInputs.scripted,
            audience:formInputs.audience,
            restrictAgents: formInputs.restrictAgents,
            multiSteps:formInputs.scripted ? multiStepConfig : [] 
        }
        if(page==='edit'){
            previewData.views=articleToEdit.views
            previewData.rating=articleToEdit.rating
            previewData = {...articleToEdit,...previewData}
        }
        openPreviewModal(previewData)
    }

    /** ------------------ On Clicking the x button next a teamspace. This feature is disabled for Nov 2023 release---------------------- */
    const removeTeamSpace = (index) => {
        setTeamSpaces(prev => prev.toSpliced(index,1))
    }

    /** ----------- On Clicking Auto-Complete in the Rich Text Editor. This needs to be refactored once API to call is implemented --------- */
    const generateAutoResponse = async (content) => {
        setShowFooterInEditor(false)
        const urlBody={operation:'autocomplete',payload:content}
        getAiResponse(urlBody)
    }

    const aiGeneratedResponsePostRequest = useMutation(callBackend, {
        onSuccess: (data) => {
            let formValueNew = {...formInputs}
            if(data &&  data?.data?.length > 0 &&  data?.data[0].message?.content?.length > 0){
                formValueNew.content = data?.data[0]?.message?.content
                setFormInputs(formValueNew)
                toast({
                    title: "Auto-complete success",
                    description: "Auto-complete request completed successfully",
                    status: "success",
                    position: "top-right",
                    duration: 5000,
                    isClosable: true,
                })
            }
            else {
                toast({
                    title: "Auto-complete no data",
                    description: "Oops!  Currently We are not able to generate any response! Please try again",
                    status: "warning",
                    position: "top-right",
                    duration: 5000,
                    isClosable: true,
                })
                setShowFooterInEditor(true)
            }
        },
        onError: (err, data) => {
        toast({
            title: "Auto-complete Error!",
            description: "Oops!  Currently We are not able to generate any response! Please try again",
            status: "error",
            duration: 5000,
            position: "top-right",
            isClosable: true,
        })
        setShowFooterInEditor(true)
        },
    })
    const {
        mutateAsync: airesponseWatcher,
        isLoading: loadingAiGeneratedResponse,
        isSuccess: result,
    } = aiGeneratedResponsePostRequest

    let getAiResponse = async (aiPayload) => {
        await airesponseWatcher({
            options: {
                url: `InputFiles/knowledgeArticles`,
                method: "POST",
                },
            payload: aiPayload,
        })
    }
    /** ------------------END Auto-complete related functions --------------- */
 
    /** ------------------- End Event Handlers -------------------------------- */

    /**   --------------- HELPER FUNCTIONS ----------------- */
    /** ---------------------- Call back function to pass to QuillEditor to map embedded video url to original URL --------------- */
    const addEmbeddedToOriginalVideoURLMapping = (embeddedURL, originalURL) => {
            setFormInputs(prev =>{
            return {...prev,embeddedToOriginalVideoUrlMap:[...prev.embeddedToOriginalVideoUrlMap,[embeddedURL,originalURL]]}
        })
    }
    /** ------------------ Remove any media - image, video, attachment from the content -------------- */
    const removeMediaContent = () => {
        const regex = /<(img|video|iframe)[^>]*>/gi
        if (regex.test(formInputs.content)) 
            setMediaContentError('Multimedia content is only supported for scripted answers; it will not be utilized for non-scripted responses.')
                // const cleanedHtml = formInputs.content.replace(/(?:&nbsp;)?<img[^>]*\/?>|<video[^>]*>.*?<\/video>|<a[^>]*>(\s*<img[^>]*\/?>\s*)<\/a>/gi, '')
        // setFormInputs(prev => ({...prev,content:cleanedHtml}))
    }

    const isValidUrl = url => {
	try {
		new URL(url)
	} catch (_) {
		return false
	}
	return true
}
    /** ----------------- Return media content in the format that Pinecone needs   ------------------- */
    const getPineconeContent = htmlString => {

        // Replace consective file attachments with newline inserted between them because turndown otherwise doesn't correctly convert them in to file attachemnts
        const tempDiv = document.createElement('div')
        tempDiv.innerHTML = htmlString.trim()
        const fileAttachmentLinks = tempDiv.querySelectorAll('a.file-attachment')

        for (let i = 0; i < fileAttachmentLinks.length - 1; i++) {
            const currentLink = fileAttachmentLinks[i]
            const nextLink = fileAttachmentLinks[i + 1]
            if (!currentLink.nextSibling || currentLink.nextSibling === nextLink) {
                const newline = document.createElement('br')
                tempDiv.insertBefore(newline, nextLink)
            }
        }
        const modifiedHtmlString = tempDiv.innerHTML

        const imageRegex = /<img\s+([^>]*)alt="[^"]*"\s*([^>]*)>/gi
        const imageReplacedHtml =  modifiedHtmlString.replace(imageRegex, '<img $1alt="image" $2>').replace(/<button>(.*?)<\/button>/gi, (_, buttonText) => { 
            return `[button]${buttonText}[/button]`
        }).replace(/<hr>/gi,'[pagebreak/]')
        const markdown = turndownService.turndown(imageReplacedHtml)

        const iframeRegex = /<iframe[^>]*class="ql-video"[^>]*src="([^"]+)"[^>]*><\/iframe>/gi

        let responseValue = markdown

        const regex = new RegExp(`${cdnUri}/${tenantId}-${config.containerKey}/`, 'g')

		responseValue = responseValue.replace(regex, '')
        responseValue = responseValue.replace(/\?(sv|st|sp|sr)[^")]*(?=[")])/g, '')

		//regex for video to map video src within SASUrl[]
        // responseValue = responseValue.replace(/<video\s+[^>]*src="([^"]*)"\s*[^>]*><\/video>/gi, (_, url) => {
        //     if (isValidUrl(url))
        //         return `[video]${url}[/video]`
		// 	return `[video]SASUrl[${url}][/video]`
		// })
        responseValue = responseValue.replace(/<video\s+[^>]*src="([^"]*)"\s*[^>]*><\/video>/gi, (_, url) => {
            url=url && url?.split('/').pop()
			return `[video]SASUrl[${url}][/video]`
		})

		//regex for images to map image src within SASUrl[]
        // responseValue = responseValue.replace(/(?<!\[)!\[[^\]]*\]\(([^)]*)\)(?!\])/g, (_, SASString) => {
		// 	if (isValidUrl(SASString)) return `![image](${SASString})`
		// 	else {
		// 		if (SASString.indexOf('SASUrl') == -1) return `![image](SASUrl[${SASString}])`
		// 		else {
		// 			let newSASString = SASString.replace(/SASUrl\[['"]*([^\]^'^"]*)['"]*\]/g, '$1')
		// 			if (newSASString) {
		// 				if (isValidUrl(newSASString)) return `![image](${newSASString})`
		// 				else return `![image](SASUrl[${SASString}])`
		// 			} else return SASString
		// 		}
		// 	}
        // })
        responseValue = responseValue.replace(/(?<!\[)!\[[^\]]*\]\(([^)]*)\)(?!\])/g, (_, SASString) => {
                SASString=SASString && SASString?.split('/').pop()
				if (SASString.indexOf('SASUrl') == -1) return `![image](SASUrl[${SASString}])`
				else {
					let newSASString = SASString.replace(/SASUrl\[['"]*([^\]^'^"]*)['"]*\]/g, '$1')
					if (newSASString) {
						return `![image](SASUrl[${SASString}])`
					} else return SASString
				}
        })
		//regex for attachment to map attachment src within SASUrl[]
            responseValue = responseValue.replace( /\[!\[image\]\((.*)\)\]\((.*)\)/gi, (matchedString, icon, link) => {
                link=link && link?.split('/').pop()   
			    return `[![image](${icon})](SASUrl[${link}])`
		})
	
        responseValue = responseValue.replace(iframeRegex, (_, srcUrl) => `[video]${formInputs.embeddedToOriginalVideoUrlMap.find(item => item[0] === srcUrl)[1]}[/video]`)
        responseValue = responseValue.replace(/\\([[\]])/g, '$1')
        return responseValue
    }
    

        /** ------------------------ Determine if Delete Pinecone endpoint should be called when updating article --------------------- */
    const callDeletePinecone = () => {
        return (initialInputState.title!== formInputs.title 
           ||(formInputs.restrictAgents && initialInputState.restrictAgents!== formInputs.restrictAgents)
           || initialInputState.scripted!== formInputs.scripted
           || (initialInputState.status[0].label!== formInputs.status[0].label && formInputs.status[0].label==='Draft') )
    }

    /** ---------------------------- Rich Text Editor related functions --------------------- */
    
    const addAttachment = useMutation(
        'uploadAttachment',
        callBackend,
        {
            onSuccess:(data,headers)=>{
                linkOfUploadFile.current = {doc:data?.data?.link || "",docName:docUniqueName,stepid: headers?.options?.stepid ? parseInt(headers?.options?.stepid) : false}
            },
            onError: (err) => {
                setErrOnUpload("Something is wrong with file upload")
            },
            
        }
    )

    const { mutate, data: attachment } = addAttachment

    const myUpload = (fileObj,types,stepid = false) => {
        const reader = new FileReader()
        
        reader.onload = async function(e) {
            const timestamp = Date.now().toString(36)
            const randomStr = Math.random().toString(36).substr(2, 5)
            const docnameUnique = fileObj.name+"_"+timestamp + randomStr+"_"+fileObj.name
            docUniqueName = docnameUnique
                }
        reader.readAsDataURL(fileObj)
        const timesp = Date.now().toString()
        let newName = `${timesp}-${fileObj.name.replace(/(?<=\S)\s+(?=\S)/g, '_')}`
        newName = newName.replace(/\(\d+\)/g, `_${Date.now()}`)        
        const renamedFile = new File([fileObj], newName, { type: fileObj.type })
        const formData = new FormData()
        formData.append("upload", renamedFile)
        formData.append('uploadConfig', JSON.stringify({ type: types, access: 'private',key:config.containerKey}))
        mutate({
            payload: formData,
            options: {
            url: `InputFiles/uploadContent`,
            method: "POST",
            stepid
            }
        })
    }

    const sanitizeQuillHtml = (html) =>{
        const replacedHtml = html.replace(/<(span|p) class="ql-size-small">(.+?)<\/\1>/g, '<h5>$2</h5>')
                                 .replace(/<(span|p) class="ql-size-large">(.+?)<\/\1>/g, '<h2>$2</h2>')
                                 .replace(/<(span|p) class="ql-size-huge">(.+?)<\/\1>/g, '<h1>$2</h1>')  
                                 .replace(/<(strong|em|u) class="ql-size-small">(.+?)<\/\1>/g, '<h5><$1>$2</$1></h5>')
                                 .replace(/<(strong|em|u) class="ql-size-large">(.+?)<\/\1>/g, '<h2><$1>$2</$1></h2>')
                                 .replace(/<(strong|em|u) class="ql-size-huge">(.+?)<\/\1>/g, '<h1><$1>$2</$1></h1>')


        return replacedHtml
    }
    /** ---------------- End Rich Text Editor related functions --------------------- */

    /** -------------------- Check if any required form inputs are missing ----------------- */
    const checkInputErrors = () =>{
        const inputErrors = {}
        inputErrors.title = formInputs.title.trim() !== '' ? '' : 'Title cannot be blank' 
        const textContent = new DOMParser().parseFromString(formInputs.content, 'text/html').body.textContent
        inputErrors.content = textContent.trim() !== '' ? '' : 'Content cannot be blank'
        return inputErrors
    }

    /** ----------------------- Check if html string has any media content -----------------*/
    function hasMediaContent (htmlString) {
        return  /<(img|video|iframe)[^>]*>/gi.test(htmlString)
    }

    /** -------------------- Check if form can be submitted ---------------------  */
    const canSubmit = () => {
        const textContent = new DOMParser().parseFromString(formInputs.content, 'text/html').body.textContent
        return (formInputs.title.trim().length === 0 || formInputs.title.length > 255 || ((textContent?.trim().length === 0 && !hasMediaContent(formInputs.content)) && (backupdescriptions?.description?.trim().length === 0 && !hasMediaContent(backupdescriptions.content)) ) || Object.values(formInputErrors).some(value=>value.length>0))
    }

    /** Convert audience data in the format Pinecone expects */
    const transformAudienceData = ()=>{
        return formInputs.audience.reduce((prev,curr,index)=>{
            const {key,values} = curr
            if(index > 0)
                return `${prev}|${key}:${values.join(',')}`
            else
                return `${key}:${values.join(',')}`
        },'')
    }
    /** ------------------------- END HELPER FUNCTIONS ------------------------ */
    const handleAddField = () => {
        if (questions && questions.length) {
            let q1 = questions[questions.length - 1]
            if (!q1 || q1?.trim() === '' ) {
                setQuestionErrHandler({ errorMsg: (<Flex alignItems={"center"} gap={2}>
                    <BsExclamationTriangle  size={"24px"}   color="#CF3626"/>
                    <div style={{fontSize: "14px",fontWeight: 500,lineHeight: "20px",color: "#CF3626"}}>
                        Fields should not be empty.
                    </div>
                </Flex>)
                })
                return
            }
        }
        setQuestionErrHandler(null)
        setQuestions([...questions,''])
    }
     
    const handleRemoveField = (index) => {
        if (questions.length > 1) {
            const updatedFields = [...questions]
            updatedFields.splice(index, 1)
            setQuestions(updatedFields)
        }
        setQuestionErrHandler(null)
    }    
   
    const handleChange = (index, newValue) => {
        const updatedFields = [...questions]
        updatedFields[index] = newValue
        setQuestionErrHandler(null)
        setQuestions(updatedFields)
    }

    const handleAudienceData = (event) => {
        event.preventDefault()
        setAudienceError('')
        setAudienceData((prevData) => {
          const updatedData = { ...prevData }
          const key = listKey === '' ? 'Whitelist' : listKey
          if (key === 'Whitelist' && updatedData['Blacklist']?.length > 0) {
            // alert("You can't add values to WhiteList while BlackList has values.");
            setAudienceError("Whitelist and Blacklist cannot be used simultaneously")
            return prevData
            
          }
      
          if (key === 'Blacklist' && updatedData['Whitelist']?.length > 0) {
            // alert("You can't add values to BlackList while WhiteList has values.");
            setAudienceError("Whitelist and Blacklist cannot be used simultaneously")
            return prevData
          }
          if (!updatedData[key]) {
            updatedData[key] = []
          }
          if (!updatedData[key].includes(audienceValue)) {
            updatedData[key].push(audienceValue)
          }
        //   setFormInputs(prev => ({...prev,audience:updatedData}))
          return updatedData
        })
        setAudienceOptions((prevOptions) =>{
          if (listKey === 'Whitelist' && audienceData['Blacklist']?.length > 0) {
            return prevOptions 
          }
          if (listKey === 'Blacklist' && audienceData['Whitelist']?.length > 0) {
            return prevOptions
          }
          else{
            return prevOptions.filter((item) => item.name !== audienceValue)
         }
  
        })
        setListKey('')
        setAudienceValue('')
      }
      
      
      const handleRemoveAudience = (key, value) => {
        setAudienceData((prevData) => {
          const updatedData = { ...prevData }
          const valueToBeDelete=value
          updatedData[key] = updatedData[key].filter((item) => item !== value)
          if (updatedData[key].length === 0) {
            delete updatedData[key]
          }
      
          return updatedData
        })
      
        setAudienceOptions((prevOptions) => [...prevOptions, { name: value }])
      }
      useEffect(()=>{
        setAudienceData(formInputs?.audience||[])
        let existingList=[]
        if(formInputs?.audience && (formInputs?.audience?.Whitelist || formInputs?.audience?.Blacklist)){
            existingList = formInputs?.audience?.Whitelist ?? formInputs?.audience?.Blacklist
        }
        let updatedData=[]
        if(audiencesRecords?.length>0){
            updatedData=existingList.length>0 ? audiencesRecords.filter((item)=>!existingList.includes(item.name)) : audiencesRecords
        }
        setAudienceOptions(updatedData)
      },[audiencesRecords])

    /**------autocomplete tavily------**/
      const enableAutoComplete = useCallback(()=>{
        setAutoCompleteDrawerSettings(true)
      })
      const disableAutoComplete = useCallback(()=>{
        setAutoCompleteDrawerSettings(false)
      })
      const onSubmitAutoComplete = useCallback((val)=>{
        if(val)onEditorChange(val)
        disableAutoComplete()
      })

    /**------multistep------**/
    const resetMultiStepError = () =>{setMultistepError({key:null,value:null})}
    
    useEffect(async()=>{
          setMultiStepConfig(multiStepConfig)
          formatMultistep()
     },[multiStepConfig])
 
     useEffect(()=>{splitMultiStep()},[])
     const splitMultiStep = (dataInsertion = false) =>{
      const chunks = dataInsertion ? dataInsertion?.split('<hr>') :formInputs?.content?.split('<hr>')
      if(chunks.length >= 2){
          const transformedChunks = chunks.map((chunk,index)=>{
              return {id:index,openState:false,content:transitionDataTemplate(chunk,'chunk'),transition:transitionDataTemplate(chunk,'removeTag')?.trim() !== ''?transitionDataTemplate(chunk,'removeTag'):'Next'}
          })
          setMultiStepConfig(transformedChunks) 
      }
    }
    const transitionChecker = async (onSubmit = false) => {
      if(multiStepConfig?.length >= 1){
          let index = 0
          for (let step of multiStepConfig){
              if(step.content === null || getPineconeContent(step?.content)?.trim() === '' ){
                  setMultistepError({key:step.id,value:`Add a description in step ${index+1}`})
                  return true
              }
              if(step.transition === null || step?.transition?.trim() === '' ){
                  setMultistepError({key:step.id,value:`Add a transition button in step ${index+1}`})
                  return true
              }
              index++
          }
      }
      if(onSubmit && multiStepConfig?.length === 1){
          setMultistepError({key:0,value:`Single step is not allowed`})
          return true
      }
      return false
    }
    const createMultiStepBox = async ( newValue )=>{
          resetMultiStepError()
          let isTransitionChecked = await transitionChecker()
          if(!isTransitionChecked){
              setMultiStepConfig((prev) => [...prev,{ id: multiStepConfig.length !==0 ? multiStepConfig[multiStepConfig.length-1]?.id+1 : 0, openState: true, content: multiStepConfig.length !==0 ? '' : formInputs?.content, transition: 'Next' }
              ,...(newValue ? [{ id:1, openState: true, content:'', transition: 'Next'}] : []) 
          ])
          }
    }
    const removeStepBox = (id) => {
      if(multiStepConfig.length === 1 ) onEditorChange(multiStepConfig[0].content)
      resetMultiStepError()
      setMultiStepConfig((prev)=>{
          return prev.filter((data)=>{
              return id !== data.id
          })
      })
    }
    const updateStep = (id,keyname,value) => {
      resetMultiStepError()
      setMultiStepConfig((prev)=>{
          return prev.map((data)=>{
                  if(data.id == id){
                      if(keyname === 'openState') data.openState = value
                      if(keyname === 'transition') data.transition = value
                  }
                  return data
              })
          })
    }
    const transitionDataTemplate = (val,state) =>{
      if(state === 'removeTag'){
          let match = val.match(/<button>(.*?)<\/button>/)
          return match ? match[1] : ''
      }
      if(state === 'addTag') return `<button>${val}</button>`
      if(state === 'chunk') return val.replace(/<button.*?<\/button>/g, '')?.trim()
      return val
    }
    const onStepEditorChange = useCallback((val,id) =>{
      resetMultiStepError()
      setMultiStepConfig((prev)=>{
          return prev.map((data)=>{
                  if(data.id === parseInt(id)){
                      data.content = val
                  }
                  return data
              })
          })
    })
    const formatMultistep = async () => {
      let stepContent = formInputs?.content
      if(multiStepConfig.length !== 0 ){
          stepContent = multiStepConfig.map((step)=>{
              let TransitionWarp = step?.transition && step?.transition?.trim() !== '' ? transitionDataTemplate(step?.transition,'addTag') : ""
              return step.content+TransitionWarp}).join('<hr>')       
      }
      return  onEditorChangeForMultiSteps(stepContent)
    }

    return (
        // Disable for Nov 2023 release <form onSubmit={handleSaveTo} data-testid={page === 'create'?'ka_create_container':'ka_edit_container'} >
        <form onSubmit={handleSubmit} data-testid={page === 'create'?'ka_create_container':'ka_edit_container'} >
           <Flex justify='flex-start' marginLeft={display==='drawer'?'10px':'0px'} padding='10px 24px 13px 0px' borderBottom='1px solid #D1D5DB' alignItems='flex-start' alignSelf='stretch' gap='10px' data-testid={page === 'create'?'ka_create_header':'ka_edit_header'}>
                <HiOutlineArrowCircleLeft cursor='pointer' size='26px' color={display==='drawer'?'#2563EB':'#94A3B8'} onClick={handleBack}  data-testid={page === 'create'?'ka_create_back_icon':'ka_edit_back_icon'}/>
                <Flex direction='column' gap='10px'>
                    <Text as='span' fontSize="16px" fontWeight='bolder' color='#000' data-testid={page === 'create'?'ka_create_header_title':'ka_edit_header_title'} >{page === 'edit'? 'Edit' : 'Create'} Article</Text>
                    {/* --------  Placeholder Breadcrums to be implented in later releases
                     <Flex alignItems='center' gap='10px'>
                        <MdMenu size='17px' color='#94A3B8' data-testid={page === 'create'?'ka_create_breadcrumbs_icon':'ka_edit_breadcrumbs_icon'}/>
                        <Text fontSize='14px' color='6B7280' data-testid={page === 'create'?'ka_create_breadcrumbs':'ka_edit_breadcrumbs'}>{`Knowledge Sources > Knowledge Articles`}</Text>
                    </Flex> */}
                </Flex>
            </Flex>
            {/* {!loading ? */}
                <Stack fontSize='14px' color='gray.700' gap='30px' padding={display==='drawer'?'15px':'20px 10px'}>
                    <InputGroup flexDirection='column' >
                        <Flex direction={display==='drawer'?'column':'row'} alignItems='flex-start'>
                            <Flex minWidth='300px' display='flex' alignItems='start' >
                                <Text as='span' className="input-label" data-testid={page === 'create'?'ka_create_title_label':'ka_edit_title_label'}>Title</Text>
                                <Text as='span' color='red' fontSize='14px'>*</Text>
                            </Flex>
                            <Flex direction='column' gap='12px' w={display==='drawer'?'700px':'830px'}>
                                <Input placeholder="Title" data-testid={page === 'create'?'ka_create_title_input':'ka_edit_title_input'} name='title' value={formInputs.title} maxW='830px' fontSize='14px' onChange={handleInputChange} onBlur={()=>{}} />
                                {(formInputErrors.title!=='' && showTitleError) &&
                                <Flex borderRadius='6px' h='40px' pl='16px' alignItems='center' gap='12px' border='1px solid #CF3626' background='#FEF2F2'>
                                    <RiCloseCircleFill cursor='pointer' size='20px' color='#F87171' onClick={()=>setShowTitleError(false)}/>
                                    <Text color='red.800' fontWeight='bolder' fontSize='14px' data-testid={page === 'create'?'ka_create_title_errors':'ka_edit_title_errors'}>{formInputErrors.title}</Text>
                                </Flex>}
                            </Flex>
                        </Flex>
                    </InputGroup>
                    {formInputs?.scripted && 
                    <InputGroup flexDirection='column' >
                        <Flex direction={display==='drawer'?'column':'row'} alignItems='flex-start'>
                            <Flex minWidth='300px' display='flex' alignItems='start' >
                                <Text as='span' fontSize='14px' color='#000' data-testid={page === 'create'?'ka_create_title_label':'ka_edit_title_label'}>Question Variants</Text>
                            </Flex>
                            <Flex direction='column' gap='12px' w={display==='drawer'?'700px':'830px'}>
                                {questions.map((varient, index) => (
                                    <Flex direction='row'key={index} height={"40px"} width={'100%'} >
                                        <Input style={{flexGrow:1}} type="text" placeholder="Type question" borderColor='#E2E2E2' color='#6B7280' fontSize='14px' placeholderColor="#A9B1BC" value={varient} onChange={(e) => handleChange(index, e.target.value)} focusBorderColor="#2563EB"/>
                                        {questions.length > 1 && <Button ml={'4px'} backgroundColor={'transparent'}  alignItems='center' p='3px' borderRadius='5px' justifyContent='center' as='span' border={'1.5px solid #CF3626'} onClick={() => handleRemoveField(index)}><DeleteIcon size='24px'  color={"#CF3626"} ></DeleteIcon></Button> }
                                        <Button ml={'4px'} backgroundColor={'transparent'}  display={index===questions.length-1 ?'flex':'none'} p='3px' alignItems='center' justifyContent='center' as='span' type='submit'  borderRadius='5px' border={'1.5px solid #2563EB'}  onClick={handleAddField}><AiOutlinePlusCircle size='24px' color="#2563EB"/> </Button>
                                    </Flex>
                                ))}
                                {questionErrHandler &&  questionErrHandler.errorMsg}
                            </Flex>
                        </Flex>
                    </InputGroup>}
                    <Flex direction={display==='drawer'?'column':'row'} alignItems='flex-start'>
                        <Flex minWidth='300px' display='flex' alignItems='start'>
                            <Text as='span' className="input-label" data-testid={page === 'create'?'ka_create_description_label':'ka_edit_description_label'}>Description</Text>
                            <Text as='span' color='red' fontSize='14px'>*</Text>
                        </Flex>
                        <Flex direction='column' gap={multiStepConfig?.length === 0 ? '15px':'0'}>
                            <Flex direction='column' gap='0px' position='relative'>
                            {(multiStepConfig?.length === 0 || !formInputs.scripted) && <Box className="create-article" height={`${parseInt(customEditorStyles.height.replace(/[^0-9]/g, ''), 10)+42}px`} position='relative' data-testid={page === 'create'?'ka_create_editor':'ka_edit_editor'}>
                                <TextEditor
                                    name='description'
                                    editorvalue={formInputs.content}
                                    myUpload={myUpload}
                                    toolBarModules={toolbar_with_media}
                                    fontSize={'inherit'}
                                    onChange={onEditorChange}
                                    errOnUpload={errOnUpload}
                                    linkOfUploadFile={linkOfUploadFile.current}
                                    customEditorStyles={customEditorStyles}
                                    // key={editor_key.current}
                                    usedOn='articles'
                                    showError={() => setMediaContentError('Multimedia content is only supported for scripted answers; it will not be utilized for non-scripted responses.')}
                                    videoSettings={tenantMediaSettings}
                                    mapEmbeddedVideo={addEmbeddedToOriginalVideoURLMapping}
                                    disableAutocompleteText = {formInputs?.title?.trim() !== ''}
                                    genAiconfig={autoComplete}
                                    autoComplete = {{autoCompleteDrawerSettings,setAutoCompleteDrawerSettings,enableAutoComplete,disableAutoComplete,onSubmitAutoComplete}}
                                    />
                                    {autoCompleteDrawerSettings && <AutoComplete selectedArticle={articleToEdit} preserveAutoComplete={props?.preserveAutoComplete} autoCompleteConfig = {{autoCompleteDrawerSettings,setAutoCompleteDrawerSettings,enableAutoComplete,disableAutoComplete,onSubmitAutoComplete,articleContent:{title:formInputs?.title?.substring(0, 100) ?? '' ,description: extractWordsFromHtmlForAutocomplete(formInputs?.content)?.substring(0, 255) ??''} }}/>}
                                    {/* ---------- Keeping this in case we want to show word count in editor later
                                    <Box position='absolute' right='10px' backgroundColor='rgba(255, 255, 255, 0.8)' padding='5px' borderRadius='5px' color='#6B7280'>
                                        {wordCount}/1000
                                    </Box> */}
                                </Box>}
                                {loadingAiGeneratedResponse &&
                                    <Box className='editorfooter' data-testid={page === 'create'?'ka_create_editor_footer_autocomplete_loading':'ka_edit_editor_footer_autocomplete_loading'} w='100%' padding='10px 19px' borderRadius='0px 0px 10px 10px' border='1px solid #ccc' marginTop='0px' borderTop='0px' bg='#e2e8f0' >
                                            <Button
                                                isLoading = {loadingAiGeneratedResponse}
                                                loadingText='We Are Generating Response for you'
                                                size={"sm"}
                                                fontSize={"12px"}
                                                bg='transparent'
                                            >
                                                Submit
                                            </Button>
                                    </Box>
                                }
                            </Flex>
                            {multiStepConfig?.length === 0 && mediaContentError.length > 0 &&
                                <Flex p='14px 16px' gap='12px' alignItems='center' borderRadius='6px' border='1px solid #E79B04' bg='#F7E9CE' color='#E79B04'>
                                    <GoAlert size='24px'/>
                                    <Text fontWeight={500}>{mediaContentError}</Text>
                                </Flex>}
                                {formInputs.scripted && multiStepConfig?.length!==0 && multiStepConfig?.map((step,index)=>{
                                return <Box key={index}>
                                            <Box transition={'all 0.3s ease-in-out'}  mb={'10px'} cursor={'pointer'}  key={index} width={"830px"} height={step.openState?'404px':'49px'} border={'1px solid #D1D5DB'} borderRadius={'5px'} padding={step.openState?'5px 15px 15px 15px':'0px 15px'} >
                                                        <Flex onClick={(event)=>updateStep(step.id,'openState',!step.openState)}  height={'49px'} alignItems={'center'} justifyContent={'space-between'} borderBottom={step.openState?'1px solid #D1D5DB':'none'}>
                                                            <Box>
                                                                <Text fontWeight={'500'} fontSize={'14px'}>Step {index+1}</Text>
                                                            </Box>
                                                            <Box>
                                                                <MdKeyboardArrowDown color="#94A3B8" size={'16px'}/>
                                                            </Box>
                                                        </Flex>
                                                        {step?.openState && <Box transition={'all 1s ease-in-out'}>
                                                        <Flex py={'10px'} height={'286px'} key={index} data-testid={page === 'create'?'ka_create_editor':'ka_edit_editor'}>
                                                            <TextEditor 
                                                                customStyle = {true}
                                                                stepId={String(step.id)}
                                                                onChange={onStepEditorChange} 
                                                                editorvalue={step?.content}
                                                                myUpload={myUpload}
                                                                toolBarModules={toolbar_with_media}
                                                                fontSize={'inherit'}
                                                                errOnUpload={errOnUpload}
                                                                {...((linkOfUploadFile.current.stepid === step.id))?{linkOfUploadFile:linkOfUploadFile.current}:{}}
                                                                customEditorStyles={customEditorStyles}
                                                                usedOn='articles'
                                                                showError={() => setMultistepError({key:step.id,value:'Multimedia content is only supported for scripted answers; it will not be utilized for non-scripted responses.'})}
                                                                videoSettings={tenantMediaSettings}
                                                                mapEmbeddedVideo={addEmbeddedToOriginalVideoURLMapping}
                                                            />
                                                        </Flex>
                                                        <Flex pt={'20px'} alignItems={'center'} justifyContent={'space-between'}>
                                                            <Flex gap={2} alignItems={'center'}>
                                                                <Text height={'20px'} width={'119px'} color={'#374151'} fontWeight={'500'} fontSize={'14px'} flexShrink={0}>Transition Button:</Text>
                                                                <Input value={step.transition} onChange={(event)=>updateStep(step.id,'transition',event.target.value)} _focus={{ ring: 0 }} fontWeight={'400'} fontSize={'14px'} height={'32px'} padding={'0px 10px'} borderRadius={'5px'} borderColor={'#E2E2E2'}/>
                                                            </Flex>
                                                            <Flex onClick={()=>removeStepBox(step.id)} cursor={'pointer'} padding={'10px'} border={'1px solid #CF3626'} borderRadius={'5px'}>
                                                                <DeleteIcon size='24px' color={"#CF3626"} ></DeleteIcon>
                                                            </Flex>
                                                        </Flex>
                                                        </Box>}
                                            </Box>
                                            {multiStepError?.key === step?.id && multiStepError?.value &&
                                            <Flex mb={'10px'} p='14px 16px' gap='12px' alignItems='center' borderRadius='6px' border='1px solid #E79B04' bg='#F7E9CE' color='#E79B04'>
                                                        <GoAlert size='24px'/>
                                                        <Text fontWeight={500}>{multiStepError?.value}</Text>
                                            </Flex>}
                                        </Box> 
                            })}        
                            {formInputs.scripted && 
                                <Box>    
                                    <Flex onClick={()=>createMultiStepBox(multiStepConfig.length?false:true)} bg="#2563EB" color="#fff" p={'4px 8px'} alignItems={'center'} justifyContent={'center'} width={'106px'} height={'32px'} borderRadius={'5px'} cursor={'pointer'}>
                                    <MdOutlineAdd color="#fff" size={'16px'}/>
                                    <Text fontSize={'14px'} fontWeight='400'>Add Step</Text>
                                    </Flex>
                            </Box>}   
                        </Flex>
                    </Flex>
                    <InputGroup>
                        <Flex  direction={display==='drawer'?'column':'row'} alignItems='start' >
                            <Box display='flex' minW='300px'><Text as='span' className="input-label" data-testid={page === 'create'?'ka_create_status_label':'ka_edit_status_label'} >Status</Text></Box>
                            <Flex alignItems='center' >
                                <Box w={display==='drawer'?'700px':'830px'} data-testid={page === 'create'?'ka_create_status_input':'ka_edit_status_input'}>
                                    <MultiSelect 
                                        width={'100%'} 
                                        name='status' 
                                        isMulti={false}
                                        options={[{label:'Draft',value:'false'},{label:'Published', value:'true'}]} 
                                        onChange={handleMultiSelectChange}
                                        // onChange={handleInputChange} 
                                        value={formInputs.status.length> 0 ? formInputs.status :[{label:'Draft',value:'false'}] } 
                                        // placeholder='Select attribute'
                                        style={{ minHeight: '30px', padding:'9px 13px', borderRadius:"6px", border:'1px solid #D1D5DB' ,width:'100%' }}
                                    />
                                </Box>
                            </Flex>
                        </Flex>
                    </InputGroup>
                    {/* -------DO NOT DELETE - These fields will be required in later releases
                     <Flex alignItems='flex-start'>
                        <Flex alignItems='center' minW='300px' gap='6px' >
                            <Text as='span'fontSize='14px' color='#000' data-testid={page === 'create'?'ka_create_review_date_label':'ka_edit_review_date_label'}>Review Date</Text>
                            <Tooltip placement='right-end' bg='transparent' color='#000' boxShadow='none'
                                label={
                                    <Flex fontSize='14px' p='10px' gap='10px' alignItems='flex-start' w='331px' bg='#fff' borderRadius='6px' boxShadow='0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)' >
                                        <HiOutlineInformationCircle size='24px' color="#E79B04"/>
                                        <Text as='span' data-testid={page === 'create'?'ka_create_review_date_tooltip':'ka_edit_review_date_tooltip'}>You'd be reminded to review this article on this date.</Text> 
                                    </Flex>
                                }
                                >
                                <span><HiOutlineInformationCircle color='#94A3B8' size='24px'/></span>   
                            </Tooltip>
                        </Flex>
                        <DatePicker
                            dateFormat='MM/dd/yyyy'
                            placeholderText='Select a date'
                            showIcon
                            onChange={(date)=>handleDateChange('reviewDate',date)}
                            minDate={new Date()}
                            selected={formInputs.reviewDate}
                            customInput={<CustomDateInput />} 
                        />  
                    </Flex>
                    <Flex alignItems='flex-start'>
                        <Flex alignItems='center' minW='300px' gap='6px' >
                            <Text as='span'fontSize='14px' color='#000' data-testid={page === 'create'?'ka_create_retire_date_label':'ka_edit_retire_date_label'}>Retire Date</Text>
                            <Tooltip placement='right-end' bg='transparent' color='#000' boxShadow='none'
                                label={
                                    <Flex fontSize='14px' p='10px' gap='10px' alignItems='flex-start' w='441px' bg='#fff' borderRadius='6px' boxShadow='0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)' >
                                        <HiOutlineInformationCircle size='24px' color="#E79B04"/>
                                        <Text as='span' data-testid={page === 'create'?'ka_create_retire_date_tooltip':'ka_edit_retire_date_tooltip'}>This article would retire and stop appearing in search results / bot conversations on this date.</Text> 
                                    </Flex>
                                }
                                >
                                <span><HiOutlineInformationCircle color='#94A3B8' size='24px'/></span>   
                            </Tooltip>
                        </Flex>
                        <DatePicker
                            dateFormat='MM/dd/yyyy'
                            placeholderText='Select a date'
                            showIcon
                            onChange={(date)=>handleDateChange('retireDate',date)}
                            minDate={new Date()}
                            selected={formInputs.retireDate}
                            customInput={<CustomDateInput />} 
                            data-testid={page === 'create'?'ka_create_retire_date_picker':'ka_edit_retire_date_picker'}
                        />  
                    </Flex> ------------ DO NOT DELETE */}
                  { /* {!formInputs.restrictAgents && 
                        <Flex direction={display==='drawer'?'column':'row'} alignItems='start'>
                            <Box display='flex' minW='300px'><Text as='span' className="input-label" data-testid={page === 'create'?'ka_create_audience_label':'ka_edit_audience_label'}>Audience</Text></Box>
                            <Flex direction='column' w={display==='drawer'?'700px':'830px'} gap={2}>
                                <Flex display='flex' alignItems='center' justify='space-between' gap='20px'>
                                    <MultiSelect name='audienceKey' isMulti={false} options={audienceAttributes?.map(attr=> ({label:attr.label,value:attr.label}))} value={audienceKey} placeholder="Select Attribute" onChange={(value,name)=>handleMultiSelectChange(value,name)}  style={{ minHeight: '30px', padding:'9px 13px', borderRadius:"6px", border:'1px solid #D1D5DB' ,width:'100%' }}>
                                    </MultiSelect>
                                    <MultiSelect name='audienceValue' value={audienceValue} options={audienceKey?.length > 0 ? audienceAttributes?.find(attr=> attr.key===audienceKey[0].label)?.valueLabels?.map(valueLabel=> ({label:valueLabel,value:valueLabel})):[]} placeholder="Select Value" onChange={(value,name)=>handleMultiSelectChange(value,name)}  style={{ minHeight: '30px', padding:'9px 13px', border:'1px solid #D1D5DB', borderRadius:"6px", width:'100%' }} >
                                    </MultiSelect>
                                    <Button disabled={audienceAttributes && audienceKey && audienceValue? false : true} border={`1px solid #D1D5DB`}
                                    background="white"
                       borderRadius='6px'
                       color="#6B7280"
                       cursor="pointer"
                       fontSize="14px"
                       fontWeight="500"
                       lineHeight="normal"
                       _disabled={{
                        cursor:"not-allowed",
                        background:"#E5E7EB",
                        border:"1px solid #E5E7EB",
                        color:"#8993A1",
                        _hover:{
                          backgroundColor: "#E5E7EB",
                          color:"#8993A1",
                          cursor:"not-allowed"
                        }
                       }}
                       _hover={{
                        background:"white"
                       }}
                         as='span' type='submit' onClick={addAudienceAttribute}>Add </Button>
                                </Flex>
                                <Flex gap={2} flexWrap='wrap'>
                                    {formInputs.audience.length!==0 &&
                                    formInputs.audience.map((attr, index) => {
                                        return (
                                            <Flex alignItems='center' flexWrap='wrap' key={index} width='fit-content' >
                                                <Flex alignItems='center' flexWrap='wrap' key={index} width='fit-content'  gap={2} border={'1px solid #1e90ff'} borderRadius='5px' p='5px' >
                                                    <Text fontSize='14px' color='#000' fontWeight='bolder' >{attr.key}</Text>
                                                    {attr.values.map((item,indexVal) => (
                                                        <Flex key={indexVal} alignItems='center' >
                                                            <Flex  display='flex' alignItems='center' gap={1} backgroundColor='#e0e7ff' borderRadius='5px' p='5px'>
                                                                <Text fontSize='14px' color='#3730a3' >{item}</Text>
                                                                <Text display='flex' alignItems='center' cursor='pointer' color='#94a3b8' onClick={() => {removeAudienceAttribute(attr.key,indexVal) }}>
                                                                <SmallCloseIcon color="#94a3b8" size='14px' />
                                                                </Text>
                                                            </Flex>
                                                            {indexVal < attr.values.length - 1 && (
                                                                <Text fontSize='14px' color='#000' ps='8px' fontWeight='bolder'>
                                                                OR
                                                                </Text>
                                                            )}
                                                        </Flex>
                                                    ))}
                                                </Flex>
                                                {index < formInputs.audience.length - 1 && (
                                                    <Text fontSize='14px' color='#000' ps='8px' fontWeight='bolder'>
                                                    AND
                                                    </Text>
                                                )}
                                            </Flex>
                                        )
                                    })}
                                </Flex>
                            </Flex>
                        </Flex>
                    }*/}
                    { !formInputs.restrictAgents && 
                        <Box display='flex'>
                        <Text my='2' fontWeight='500' fontSize='14px' minWidth='300px' >Audience</Text>
                        <Box width='100%'>
                            <Box width='100%'>
                                <Box display='flex' gap='20px'>
                                <Select onChange={(event)=>{setListKey(event.target.value)}} value={listKey} defaultValue='Whitelist' >
                                    <option value='Whitelist' >Whitelist</option>
                                    <option value='Blacklist' >Blacklist</option>
                                </Select>
                                <Select onChange={(event)=>{setAudienceValue(event.target.value)}} value={audienceValue} placeholder="Audience(s)" >
                                    {
                                    audienceOptions.map((item)=>{
                                        return <option key={item.name} value={item.name}>{item.name}</option>
                                    })
                                    }
                                </Select>
                                <Button onClick={handleAudienceData} disabled={!audienceValue}>Add</Button>
                                </Box>
                            </Box>
                            {audienceError && <Box my='5px' display='flex' gap='5px' alignItems='center' fontSize={'14px'} color={'red'} >
                                <BiError />
                                <Text as='span' >{audienceError}</Text>
                            </Box>}
                            <Box>
                            {Object.entries(audienceData).map(([key, values]) => (
                            <Box display='flex' key={key} mt="10px" alignItems='center' flexWrap='wrap' width='fit-content'  gap={2} border={'1px solid #1e90ff'} borderRadius='5px' p='5px'>
                                <Text color='#000' fontWeight={'500'}>{key} Audience: </Text>
                                <Box display={'flex'} gap='10px'>
                                {values.map((value) => (
                                    <Flex key={`${key}-${value}`} display='flex' alignItems='center' gap={1} backgroundColor='#e0e7ff' borderRadius='5px' p='5px'>
                                    <Text fontSize='14px' color='#3730a3' >{value}</Text>
                                    <Text display='flex' alignItems='center' cursor='pointer' color='#94a3b8' onClick={() => handleRemoveAudience(key, value)}>
                                        <SmallCloseIcon color="#000" size='14px' />
                                    </Text>
                                    </Flex>
                                ))}
                                </Box>
                                </Box>
                                ))}
                            </Box>
                        </Box>
                      </Box>
                    }
                    <InputGroup>
                        <Box display='flex' minW='300px'><Text as='span' className="input-label" data-testid={page === 'create'?'ka_create_scripted_label':'ka_edit_scripted_label'}>Scripted Answers</Text></Box>
                        <Switch id='scripted-answers' data-testid={page === 'create'?'ka_create_scripted_input':'ka_edit_scripted_input'} _checked={{ "& .chakra-switch__track": { background: "#2563EB"}}} colorScheme='blue' name='scripted' value={formInputs.scripted} isChecked={formInputs.scripted} onChange={handleInputChange}/>        
                    </InputGroup>
                    <InputGroup>
                        <Flex alignItems='center' minW='300px' gap='6px'>
                            <Text as='span' style={{fontSize:'14px', color:"#374151", fontWeight:"500",lineHeight:"normal"}} data-testid={page === 'create'?'ka_create_restrict_agents_label':'ka_edit_restrict_agents_label'} >Restrict Access to Agents</Text>
                            <Tooltip placement='right-end' bg='transparent' color='#000' boxShadow='none'
                               label={
                                <Flex fontSize='14px' p='10px' gap='10px' alignItems='flex-start' w='331px' bg='#fff' borderRadius='6px' boxShadow='0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)' >
                                    <HiOutlineInformationCircle size='24px' color="#E79B04"/>
                                    <Text as='span' data-testid={page === 'create'?'ka_create_restrict_agents_tooltip':'ka_edit_restrict_agents_tooltip'}>Restrict access only to agents, employees won't see this knowledge article.</Text> 
                                </Flex>
                               }
                            >
                                <span><HiOutlineInformationCircle color='#94A3B8' size='24px'/></span>   
                           </Tooltip>
                        </Flex>
                        <Switch id='restrict-agents' data-testid={page === 'create'?'ka_create_restrict_agents_input':'ka_edit_restrict_agents_input'} _checked={{ "& .chakra-switch__track": { background: "#2563EB"}}} colorScheme='blue' name='restrictAgents' value={formInputs.restrictAgents} isChecked={formInputs.restrictAgents} onChange={handleInputChange}/>        
                    </InputGroup>
                    {/* ------DO NOT DELETE, This field will be used in later releases 
                    <InputGroup>
                        <Flex alignItems='center' w='300px' gap='6px'>
                            <Text as='span' fontSize='14px' color='#000' data-testid={page === 'create'?'ka_create_restrict_teamspace_label':'ka_edit_restrict_teamspace_label'}>Restrict Access to TeamSpace</Text>
                            <Tooltip placement='right-end' bg='transparent' color='#000' boxShadow='none'
                               label={
                                <Flex fontSize='14px' p='10px' gap='10px' w='547px' alignItems='flex-start' bg='#fff' borderRadius='6px' boxShadow='0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'>
                                    <HiOutlineInformationCircle size='24px' color="#E79B04"/>
                                    <Text as='span' data-testid={page === 'create'?'ka_create_restrict_teamspace_tooltip':'ka_edit_restrict_teamspace_tooltip'}>Restrict access only to the TeamSpace. Helpful for confidential documents to be accessible to authorised personnel only.</Text> 
                                </Flex>
                               }
                            >
                                <span><HiOutlineInformationCircle color='#94A3B8' size='24px'/></span>   
                           </Tooltip>
                        </Flex>
                        <Switch id='restrict-teams' data-testid={page === 'create'?'ka_create_restrict_teamspace_input':'ka_edit_restrict_teamspace_input'} _checked={{ "& .chakra-switch__track": { background: "#2AC769"}}} colorScheme='blue' name='restrictTeamSpaces' value={formInputs.restrictTeamSpaces} isChecked={formInputs.restrictTeamSpaces} onChange={handleInputChange}/>        
                    </InputGroup>
                    {formInputs.restrictTeamSpaces && 
                        <Flex maxW='1128px' p='20px' borderRadius='8px' bg='#F8F9FD' alignItems='flex-start' fontSize='14px'>
                            <Text  color='#000' w='245px'>TeamSpace access</Text>
                            <Flex p='5px' gap='14px' direction='column'>
                                <Flex gap='4px' color='#2563EB'>
                                    <FiEdit size='19px'/>
                                    <Text as='span'>Choose TeamSpace</Text>
                                </Flex>
                                <Flex gap='20px'>
                                    {teamSpaces.map((team,index) => (
                                        <Flex key={index} borderRadius='4px' bg='#DEE8FC' alignItems='center' gap='10px' p='2px 6px'>
                                            <Text as='span' color='rgba(0, 0, 0, 0.85)' fontWeight='bolder'>{team}</Text>
                                            <SmallCloseIcon color='#000' size='24px' onClick={()=>removeTeamSpace(index)}/>
                                        </Flex>
                                    ))}
                                </Flex> 
                            </Flex>
                        </Flex>
                    } -------DO NOT DELETE*/}
                    <Box my='2' maxW='1128px' display='flex' justifyContent='flex-end' spacing='10px'>
                      <HStack spacing='10px'>
                        <Button bg="transparent" height='36px' variant='outline' className="secondaryButton" onClick={handleBack} data-testid={page === 'create'?'ka_create_cancel_button':'ka_edit_cancel_button'}  >Cancel</Button>  
                        {/* <Button bg="#2563EB" padding='0px 28px' height='36px' color="#fff" fontSize='14px' isDisabled={canSubmit()} onClick={openPreview} data-testid={page === 'create'?'ka_create_preview_button':'ka_edit_preview_button'} >Preview</Button>  */}
                        {openPreviewModal && <Button className="secondaryButton" height='36px' onClick={openPreview} data-testid={page === 'create'?'ka_create_preview_button':'ka_edit_preview_button'} >Preview</Button> }
                        <Button height='36px' type='submit' _disabled={{
                        _hover:{backgroundColor:"#E5E7EB",color:"#8993A1"},
                        backgroundColor:"#E5E7EB",
                        color:"#8993A1",
                        cursor:"not-allowed"
                    }}
                    _hover={{
                        backgroundColor:"#0047EB"
                    }}
                    bg="#2563EB"
                    color="#fff" fontSize='14px' fontWeight='500'
                     isDisabled={canSubmit()} data-testid={page === 'create'?'ka_create_save_button':'ka_edit_save_button'} >Save</Button>
                        {/* <Button bg="#2563EB" padding='0px 28px' height='36px' color="#fff" fontSize='14px' type='submit' isDisabled={canSubmit()} data-testid={page === 'create'?'ka_create_save_button':'ka_edit_save_button'} >Save to</Button>             */}
                      </HStack>
                  </Box>         
                </Stack>
                {/* ---------Disabled for Nov 2023 release --------------
                <SaveToDrawer openDrawer={openDrawer} closeDrawer={()=>setOpenDrawer(false)} handleSubmit={handleSubmit} />  */}
        </form>
    )
}

export default KnowledgeArticlesCreatePage