import { useState, useEffect, createContext, useContext} from 'react'
import { useMutation, useQueryClient, useQuery } from 'react-query'
import { ToastContainer, toast } from "react-toastify"
import { useServicesContext } from "services/apiServices"
import {WorkspaceContext} from '../../../commonComponents/Layout/Layout'

const DocumentContext = createContext(null)

const initialState = {
    documents:[],
    refreshDocs:true,
    checkedFiles:[],
    searchQuery:'',
    isFetching:true,
    totalRecords:0,
    currentPage:1,
    itemsPerPage:100,
    // jobStatus:[],
    refreshRequests:false
}

export function DocumentContextProvider({children}) {
    const {apiCall,createOrUpdateAsync,globalState:commonGlobalState,updateDocuments,triggerRefetchTotalRecords} = useServicesContext()
    const {workspaceMetadata} = useContext(WorkspaceContext)
    const [globalState,setGlobalState] = useState(initialState)
    const top = globalState.currentPage * globalState.itemsPerPage
    const skip = top - globalState.itemsPerPage

    const transformDate = date => {
        const utcDateString = date
        const utcDate = new Date(utcDateString)
        const localDate = new Date(utcDate.getTime())
        const options = {
          hour12: true,
          hour: 'numeric',
          minute: 'numeric',
          day: 'numeric',
          month: 'numeric',
          year: 'numeric'
        }
        return localDate.toLocaleString('en-US', options)
    }
    
    const getDocuments = () => apiCall({
        options: {
        url: `InputFiles/getFiles`,
        method: "GET",
        query:`$filter=(substringof('.pdf', docName) or substringof('.txt', docName) or substringof('.docx',docName)) and status eq 'Published' and docType ne 'url' and docType ne 'sharepoint_site' and docType ne 'article'&$skip=${skip}&$top=${globalState.itemsPerPage}&$orderby=updatedAt desc`
        },
    })
    const { isFetching, data,} = useQuery(['documents'], ()=>getDocuments(),{enabled:globalState.refreshDocs})

    // const getJobStatus = () => apiCall({
    //     options: {
    //     url: `job/itemStatus`,
    //     method: "GET",
    //     query:`$filter=(type eq 'files')&$skip=0&$top=100000&$orderby=createdAt desc`
    //     },
    // })
    // const {data:requestsData} = useQuery('requests',()=>getJobStatus(),{enabled:globalState.refreshRequests})

    useEffect(()=>{
    if(data){
        const fileTypesFilter = data.data.rows.map(item=>{
        return {...item,
            docType:item.docType==='application/pdf' ? 'pdf'
                    :item.docType==='text/plain' ? 'text'
                    :item.docType==='application/vnd.openxmlformats-officedocument.wordprocessingml.document' ? 'docx'
                    :item.docType==='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? 'xlsx'
                    :'csv',
            createdAt:transformDate(item.createdAt),
            updatedAt:transformDate(item.updatedAt),
                }})
    
        setGlobalState(prevState=>({...prevState,documents:fileTypesFilter,totalRecords:data.data.count,refreshDocs:false}))
        updateDocuments()
    }
    },[data])

    // useEffect(()=>{
    //     if(requestsData){
    //         setGlobalState(prev=>({...prev,jobStatus:requestsData.rows,refreshRequests:false}))
    //     }
    // },[requestsData])


    useEffect(()=>{
        setGlobalState(prevState=> ({...prevState,refreshDocs:commonGlobalState.refetchDocuments}))
     },[commonGlobalState.refetchDocuments])

    useEffect(()=>{
        setGlobalState(prevState=>({...prevState,isFetching}))
    },[isFetching])

    useEffect(()=>{
        setGlobalState(prevState=>({...prevState,refreshDocs:true}))
    },[globalState.currentPage,globalState.itemsPerPage,workspaceMetadata])

   
    const updateItemsPerPage = val => setGlobalState(prevState=>({...prevState,itemsPerPage:val,currentPage:1}))

    const updateCurrentPage = val => setGlobalState(prevState=>({...prevState,currentPage:val}))

    const handleCheckAllFiles = (event) =>{
        const isChecked = event.target.checked
        const listedFiles=globalState.documents.filter(e=>{return e.workspace===workspaceMetadata})
        if(isChecked)
            setGlobalState(prevState=>({...prevState,checkedFiles:[...prevState.checkedFiles,...listedFiles.map(file=>file.id)]}))
        
        else
            setGlobalState(prevState=>({...prevState,checkedFiles:prevState.checkedFiles.filter(file=>!listedFiles.some(item=>item.id===file))})) 
    }

    const handleCheckboxChange = (event) => {
        const file = event.target.value
        const isChecked = event.target.checked
        if (isChecked) {
          !globalState.checkedFiles.includes(file) && setGlobalState((currentState)=>({...currentState,checkedFiles:[...currentState.checkedFiles, file]}))
        } else {
            setGlobalState((currentState)=>({...currentState,checkedFiles:currentState.checkedFiles.filter((f) => f !== file)}))
        }
      }

    const onUploadSuccess = () =>{
        setGlobalState(prevState=>({...prevState,refreshDocs:true}))
        triggerRefetchTotalRecords()
    }


    const onUploadFailure = () =>{
        setGlobalState(prevState=>({...prevState,refreshDocs:false}))
    }

    const bulkDelete=async ()=>{
        const data= {
            id:globalState.checkedFiles,
            docName:globalState.documents.filter(item=>globalState.checkedFiles.includes(item.id)).map(item=>item.docName)
        }
        const response= await createOrUpdateAsync({url:'InputFiles/update_status',method:'PUT',variables:'deleteDocument'},data)
        if(response.status===200){
            toast.success('Document deleted successfully')
            setGlobalState(prevState=>({...prevState,checkedFiles:[],refreshDocs:!prevState.refreshDocs}))
            triggerRefetchTotalRecords()
        }     
        else{
            toast.error('Error when deleting document in Pinecone')
            setGlobalState(prevState=>({...prevState,checkedFiles:[]}))
        }
    }

    return (
        <DocumentContext.Provider value={{globalState,updateItemsPerPage,updateCurrentPage,handleCheckAllFiles,handleCheckboxChange,onUploadSuccess,onUploadFailure,bulkDelete}}>
            {children}
        </DocumentContext.Provider>
    )
}

export function useDocumentContext() {
    return useContext(DocumentContext)
  }
