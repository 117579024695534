import {
    Divider,Button,
    Flex,Stack,Box,InputGroup,InputRightElement,Input,Image,Text, UnorderedList, ListItem,Spinner,IconButton,Tooltip, useToast,Progress
  } from "@chakra-ui/react"
  import {
    useQuery,useMutation,useQueryClient
  } from 'react-query'
  import React,{useState,useEffect, useContext,useRef} from "react"
  import {FiSearch,FiEdit,FiUpload} from "react-icons/fi"
  import norecord from '../../../assets/norecord.png'
  // import parse from 'html-react-parser'
  import { read, utils } from "xlsx"
  import * as XLSX from 'xlsx'
  import axios from "axios"
  import marked from 'marked'
  import { v4 as uuidv4 } from 'uuid'
  import Papa from 'papaparse'
  import {GrFormPrevious,GrFormNext} from 'react-icons/gr'
  import {MdOutlineRemoveRedEye,MdOutlineStarBorder} from 'react-icons/md'
  import {HiOutlineTrash,HiOutlineFunnel, HiArrowUpTray, HiArrowDownTray} from 'react-icons/hi2'
  import {HiOutlineInformationCircle} from 'react-icons/hi'
  import Pagination from '../../commonComponents/Pagination'  
  import {useKnowledgeArticlesContext} from './context/KnowledgeArticlesContext'
  import MultiSelect from "./utils/MultiSelect"
  import { WorkspaceContext } from "app/commonComponents/Layout/Layout"
  import {useServicesContext } from "services/apiServices"
  import { ArrowDownIcon, ChevronDownIcon } from "@chakra-ui/icons"
  import {getPineconeContent} from '../../components/KnowledgeArticles/KnowledgeArticlesCreatePage'
  import config from '../../../config'
  import turndown from 'turndown'
  import AppContext from 'AppContext'

  const escapeRegExp = str => str.replace(/[.\*+?^${}()|[\]\\]/g, '\\$&')
  const replaceAll = (str, find, replace) => str.replace(new RegExp(escapeRegExp(find), 'g'), replace)
  const KnowledgeArticlesTable = () => {
    const {globalState,openCreatePage,openEditPage,openDetailPage,openDeleteModal,updateListPageProps,updateCreateEditPageProps,resetListPageProps,refetchArticles} = useKnowledgeArticlesContext()
    const {apiCall } = useServicesContext()
    const {  identity } = useContext(AppContext)
    const [searchQuery,setSearchQuery]=useState(globalState.listPageProps.searchQuery)  
    const records = globalState.articles
    const [filterRecords,setFilterRecords] = useState(globalState.listPageProps.filterRecords)
    const [currentPage, setCurrentPage] = useState(globalState.listPageProps.currentPage)
    const [itemsPerPage, setItemsPerPage] = useState(globalState.listPageProps.itemsPerPage)
    const queryClient = useQueryClient()
    const importRef = useRef()
    const [allowImport, setAllowImport] = useState(true)
    const toast = useToast()
    const cdnUri = 'https://primarycdn.blob.core.windows.net'
    const top = currentPage * itemsPerPage
    const skip = top - itemsPerPage
    const pages = []
    const [isBoxOpen, setIsBoxOpen] = useState(false)
    const { workspaceMetadata,workspaceOptions } = useContext(WorkspaceContext) 
    const tenantId = identity.profile.tenantUid
       const turndownService = new turndown()
        turndownService.addRule('strikethrough', {
            filter: ['del', 's', 'strike'],
            replacement: function (content) {
                return '~~' + content + '~~'
            },
        })
        turndownService.addRule('h5', {
            filter: ['h3', 'h3', 'smallheading'],
            replacement: function (content) {
                return '##### ' + content
            },
        })
        turndownService.keep( ['video','iframe'])
        const levelArray = []
        turndownService.addRule('bulletDetector', {
                filter: 'li',
            replacement: function (content, node) {
                let indent_level = 0
                //Get the indent level of this li element
                node.classList.forEach(name => { 
                    if (name.startsWith('ql-indent-'))
                        indent_level = parseInt(name.replace('ql-indent-',''))
                })
                // Check if levelArray has indent_level +1 number of elements. If not, add that many elements to level Array and set their value to 1, to indicate starting numbering from 1.
                if (levelArray.length <= indent_level) {
                    const len = levelArray.length
                    for (let i = len; i <= indent_level; i++)
                        levelArray.push(1)
                }
                //If the current li is a first level list i.e. it doesn't have a ql-indent class name, and it's the first child then reset levelArray[0] to 1 to restart numbering from 1
                if (indent_level == 0 && !node.previousSibling)
                    levelArray[0] = 1
    
                const bulletType = node.parentNode.nodeName === 'UL' ? '-' : levelArray[indent_level] + '.'
                //Increment the levelArray at this indent level index to continue numbers for any subsequent li element at the same level from the current number.
                levelArray[indent_level] += 1
                 //Reset numbering of all higher indent level to 1, to restart numbering of any nested lists under this li from 1. 
                for (let i = indent_level + 1; i < levelArray.length; i++){
                    levelArray[i] = 1
                }
                return '\t'.repeat(indent_level) + bulletType + ' ' + content.trim()+'\n'
     
            }
        })
    const recordswithWorkSpace=records.filter((element)=>{
      if(workspaceOptions.length>1){
        if(workspaceMetadata==='noWorkSpace') return element.workspace===null
        else if(element.workspace===workspaceMetadata){
          return element
        }
     }
     else{
       return element
     }
    })

    const totalRecordsToBeDisplayed = recordswithWorkSpace.filter((data)=>{
     return  ((searchQuery==="")||((data.articleData.title.toLowerCase().includes(searchQuery.toLowerCase()))||(data.articleData.content.toLowerCase().includes(searchQuery.toLowerCase()))||(data.articleData.questions?.some(query => query.toLowerCase().includes(searchQuery.toLowerCase())))))
    }).filter((data=>{
      return (filterRecords[0].value==='draft'?data.articleData.status[0].label==='Draft':true)
    }))
    const currentPageRecords = totalRecordsToBeDisplayed.slice(skip,top)

    const pagesLength=Math.max(1,Math.ceil(totalRecordsToBeDisplayed.length/ itemsPerPage))
    for (let i = 1; i <=pagesLength ; i++) {
    pages.push(i)
    }
    
    /** ------------------------------- API CALLS ----------------------------- */

   
    /** --------------------------- Call Backend API to upload scripted Answers ------------ */
    const mutation = useMutation(
      apiCall,
      {
          onSuccess:(data)=>{
              queryClient.invalidateQueries('articles')
              resetListPageProps()
              refetchArticles()
              toast({
                    title: 'Upload Success',
                    description: 'Scripted answers uploaded successfully' ,
                    status: 'success',
                    duration: 5000,
                    position: 'top-right',
                    isClosable: true,
                  })
              setAllowImport(true)    
          },
          onError: (err) => {
            queryClient.invalidateQueries('articles')
            resetListPageProps()
            refetchArticles()
            if(/timeout of \d+ms exceeded/.test(err.message))
              toast({
                  title: 'Upload Timeout',
                  description: 'Uploading scripted answers timedout. The upload may have suceeded' ,
                  status: 'warning',
                  duration: 5000,
                  position: 'top-right',
                  isClosable: true,
              }) 
              else if (err?.response?.data?.message.includes("ON CONFLICT DO UPDATE command cannot affect row a second time")) {
                toast({
                    title: 'Upload Error',
                    description: `There are some duplicate entries in the file you're trying to upload, please remove the duplicate entries and try again.`,
                    status: 'error',
                    duration: 5000,
                    position: 'top-right',
                    isClosable: true,
                }) 
              }
              else if (err?.response?.data?.message.includes("value too long for type character varying(255)")) {
                toast({
                    title: 'Upload Error',
                    description: `Article titles must not exceed 255 characters`,
                    status: 'error',
                    duration: 5000,
                    position: 'top-right',
                    isClosable: true,
                }) 
              }
              else
              toast({
                  title: 'Upload Error',
                  description: 'There was an error uploading scripted answers.' ,
                  status: 'error',
                  duration: 5000,
                  position: 'top-right',
                  isClosable: true,
              }) 
            setAllowImport(true)
          }
      }
    )
    const uploadScriptedAnswers = async (scriptedArticles) => {
      try{
        const payloadData = createArticlePayload(scriptedArticles)
        const urlBody ={
          operation:"upsert_ScriptedAnswers",
          payload:payloadData,
          workspace:workspaceMetadata
        }
        mutation.mutate({
          options: {
          url: `InputFiles/knowledgeArticles`,
          method: "POST",
          },
          payload: urlBody,
        })
        setIsBoxOpen(false)
      }catch(error){
        toast({
          title: 'Upload Error',
          description: 'There was an error uploading scripted answers.' ,
          status: 'error',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        })
        setAllowImport(true)
      }
    }
    /** -------------------------------------- END API CALLS ----------------------- */


    useEffect(()=>{
      setCurrentPage(globalState.listPageProps.currentPage)
      setItemsPerPage(globalState.listPageProps.itemsPerPage)
      setSearchQuery(globalState.listPageProps.searchQuery)
      setFilterRecords(globalState.listPageProps.filterRecords)
    },[globalState.listPageProps])

    /**   --------------- HELPER FUNCTIONS ----------------- */
    /** ---------------------------- Check that Uploaded Scripted Answer Excel file has the expected format ------------- */
    const validateExcelData = (jsonData) => {
      const desiredColumnCount = 3
      const desiredColumnNames = ['custom_questions', 'custom_answers','properties']
  
      if (jsonData[0].length !== desiredColumnCount) {
        return {status:false,message:'Uploaded file was not a valid scripted answer file. Please upload a valid scripted answer file.'}
      }

      for (let i = 0; i < desiredColumnCount; i++) {
        if (jsonData[0][i] !== desiredColumnNames[i]) {
          return {status:false,message:'Uploaded file was not a valid scripted answer file. Please upload a valid scripted answer file.'}
        }
      }
      for(let i=0;i<jsonData.length;i++){
        if(jsonData[i][0].length>255){
          return {status:false,message:'Article titles must not exceeds 255 characters'}
        }
      }
      return {status:true,message:'Scripted answers successfully uploaded'}
    }

    /** -------------------------- Create Article data from imported scripted answer as will be stored in DB -----------------  */
    const createArticlePayload = (data) => {
      const result = {
        dbData:[],
        pineconeData:[]
      }
      function transformContentWithSASUrl(input) {
        const regex = /(!\[.*?\]\()?(https?:\/\/[^\s)]+)(\))?|\[video\](https?:\/\/[^\s\]]+)\[\/video\]/g
          return input.replace(regex, (match, imageOpen, url, imageClose, videoUrl) => {
          const finalUrl = url || videoUrl 
          if (/SASUrl\[[^\]]+\]/.test(match)) {
            return match
          }
      
          if (finalUrl) {
            const fileNameMatch = finalUrl.match(/\/([^\/?#]+)(?:\?|#|$)/)
            const fileName = fileNameMatch ? fileNameMatch[1] : "UnknownFile"
            const isPdf=fileName.includes('pdf')

      
            if (imageOpen) {
              return `${imageOpen}SASUrl[${fileName}]${imageClose || ')'}`
            } else if (videoUrl) {
              return `[video]SASUrl[${fileName}][/video]`
            }else if(isPdf){
              return `SASUrl[${fileName}])`
            }
             else {              
              return `SASUrl[${fileName}]`
            }
          }
          return match 
        })
      }
      data.forEach(item =>{
        const htmlContent = markdownToHtml(item[1])
        const markdownContent = item[1].replace(/(\[linebreak\/\])/g, '\n')
        const description = extractWordsFromHtml(htmlContent)
        const pineConeDescription = transformContentWithSASUrl(markdownContent)
        const existingArticle = records.find(article=>article.articleData.title.trim()===item[0])
        const articleId = existingArticle? existingArticle.articleData.id: uuidv4()
        let title = item[0].split('|') 
        result.dbData.push(
          {
            id:articleId,
            title: title.length >= 2 ? title[0] : item[0],
            content:htmlContent,
            description,
            questions: title.length >= 2 ? title.filter((_, index) => index !== 0) : [''],
            scripted:true,
            status:[{label:'Published', value:'true'}],
            // audience:transformAudienceData(item[2]),
            audience:[],
            restrictAgents:false,
            restrictTeamSpace: false,
            resolution_note:false,
            views:0,
            rating:0
          })
        result.pineconeData.push(
          {
            id:articleId,
            description:pineConeDescription,
            title:item[0],
            property:item[2]?`${item[2]}|team:${workspaceMetadata}`:`team:${workspaceMetadata}`
          })
        })
        
        return result
    }

    /** --------------------------- Convert properties column in scripted answers file to the format the multiselect expects ---------------- */
    const transformAudienceData = (data) => {
      return data.split('|').map(item=> {
        const kv = item.split(':')
        return {
          key:kv[0],
          values:kv[1].split(',')
        }
      })
    }

    /**---------------------------- Extract words from Article content in html string form --------------------------- */
    const extractWordsFromHtml = (htmlString) => {
      // const textContent = htmlString.replace(/<\/(p|li)>/g, ' ').replace(/<[^>]*>/g, '').trim()
      const textContent = new DOMParser().parseFromString(htmlString, 'text/html').body.textContent
      const words = textContent.split(/[\s\t]+/).slice(0,80)
      // const sentences = textContent.split(/([.!?])/g)
      // const cleanedSentences = sentences
      //     .filter((sentence) => sentence.trim().length > 0)
      //     .map((sentence) => sentence.trim())
      // let combinedWordCount = 0
      // const selectedSentences = []

      // for (let i = 0; i < cleanedSentences.length; i += 2) {
      //     const sentence = cleanedSentences[i]
      //     const punctuation = cleanedSentences[i + 1] || '' 
      //     const words = sentence.split(/[\s\t]+/) 
      
      //     if (combinedWordCount + words.length <= 80) {
      //       selectedSentences.push(sentence + punctuation)  
      //       combinedWordCount += words.length
      //     } else {
      //       break
      //     }
      //   }
      // return selectedSentences.join(' ')
      return words.join(' ')
    }
    /** -----------------------Convert markdown string to html string ---------------------------- */ 
    const downloadTemplate = () => {
      const fields = {
        custom_questions: { label: 'Custom Question' },
        custom_answers: { label: 'Custom Answers' },
        properties: { label: 'Properties' }
      }
      const importKeys = ['custom_questions', 'custom_answers', 'properties']
      const headers = importKeys
        .map(k => fields[k] && fields[k].label)
      const data = [
        { custom_questions: '', custom_answers: '', properties: '' },
      ]
      const ws = XLSX.utils.json_to_sheet(data, { header: importKeys })
      ws['!cols'] = headers.map(() => ({ wch: 50 }))
      for (let col = 0; col < headers.length; col++) {
        const cellAddress = { r: 0, c: col }
        const cell = ws[XLSX.utils.encode_cell(cellAddress)]
        if (cell) {
          cell.s = {
            fill: {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '00142E50' },
            },
            font: {
              color: { rgb: 'FFFFFF' },
              bold: true,
            },
            alignment: {
              horizontal: 'center',
              vertical: 'center',
            },
          }
        }
      }
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Template')
      const filename = 'Knowledge_Article_template.xlsx'
      XLSX.writeFile(wb, filename)
    }
    const markdownToHtml = (markdownString) => {
        let contentStateeditor = marked(markdownString, {renderer: render_content()})
        contentStateeditor = contentStateeditor.replace(/\[linebreak\/\]<img/g, '<img').replace(/\>\[linebreak\/\]/g, '>').replace(/\}\[linebreak\/\]/g, '}')
        contentStateeditor = contentStateeditor.replace(/\\n/g, '\n')
        .replace(/\[button\](.*?)\[\/button\]/g, (match, p1) => {
          return `<button>${p1 ?? 'Next'}</button>`
        }).replace(/\[pagebreak\/\]/g, '<hr>')
        .replace(/(\[button\]Next\[\/button\]\[pagebreak\/\])/g, '---------------')
        .replace(/(\[button\]Next\[\/button\])/g, '')
        .replace(/\[linebreak\/\]<img/g, '<img').replace(/\>\[linebreak\/\]/g, '>').replace(/\}\[linebreak\/\]/g, '}')
        .replace(/(\[linebreak\/\])/g, '<br>')
        .replace(/<video[^>]*>([\s\S]*?)<\/video>/gi, `![image](${cdnUri}/cdn/thumbnail.png)`)
        .replace(/^(\d+\.)\s*(.*?)$/gm, "<br>$1$2")

        contentStateeditor = replaceAll(contentStateeditor, '<p></p>)', '')
        if (! contentStateeditor.includes('$schema'))  contentStateeditor =  contentStateeditor.replace(/\{.*?\}/g, '')
        else  contentStateeditor =  contentStateeditor.replace(/\\+([\[\]\*_])/g, '$1')

        let imagePixel = []
        let splitImgTag = contentStateeditor.split('"Image">')
        if (splitImgTag.length > 1) {
          splitImgTag.forEach(e => {
            let reg = e.split('}')[0].split('{')[1]
            if (reg) imagePixel.push(`style="${reg.replaceAll('=', ':').split(' ').join(';').concat(';')}"`)
          })
          if (imagePixel.length) {
            contentStateeditor = contentStateeditor
              .split('title="Image">')
              .map((x, idx) => {
                return x.concat(`"Image" ${imagePixel[idx]} >`)
              })
              .join('\n')
              .replace(/\{.*?\}/g, '')
              .replace('"Image" undefined >', '')
          }
        }
        contentStateeditor = replaceAll(contentStateeditor, '"Image" undefined >', '')
        return contentStateeditor
    }
    /** ----------------------- Renderer to be used by marked for converting markdown to html------------------- */
    const render_content = () => {
      let render = new marked.Renderer()
      render.list = function (body, ordered, start) {
        let newtext = body.replace(/(<p>)/gim, '').replace(/<\/p>/gim, '')
        if (ordered) {
          return `<ol>${newtext}</ol>`
        } else {
          return `<ul>${newtext}</ul>`
        }
      }

      render.blockquote = function (quote) {
        let newtext = quote.replace(/(<p>)/gim, '').replace(/<\/p>/gim, '')
        return `<blockquote>${newtext}</blockquote>`
      }
      render.code = function (string) {
        return string
      }
      render.image = function (href, title, text, alt, height, width) {
        var newImage = `<img src="${href}" alt="Image" title="Image">`
        return newImage
      }
      return render
    }
    /** ----------------------------Event Handlers --------------------------- */
    /** -------------------------- Handle clicking on Create new article ------------------------- */
    const handleCreateArticle = () => {
      updateListPageProps({
        currentPage,
        itemsPerPage,
        searchQuery,
        filterRecords
      })
      openCreatePage()
    }
    /**  -------------------------------- Handle clicking on Edit article icon -------------------------- */
    const handleEditArticle = (id) => {
      updateListPageProps({
        currentPage,
        itemsPerPage,
        searchQuery,
        filterRecords
      })
      updateCreateEditPageProps('list')
      openEditPage(id)
    }
  
    /**  -------------------------------- Handle clicking on article to view details -------------------------- */
    const handleArticleDetail = (id) => {
      updateListPageProps({
        currentPage,
        itemsPerPage,
        searchQuery,
        filterRecords
      })
      openDetailPage(id)
    }

      
    /**  -------------------------------- Handle clicking on delete article icon -------------------------- */
    const handleArticleDelete = (dbId,articleId,callPinecone) => {
      updateListPageProps({
        currentPage,
        itemsPerPage,
        searchQuery,
        filterRecords
      })
      openDeleteModal(dbId,articleId,callPinecone)
    }
    /** ---------------------- Handle Clicking on Import Scripted Answers button -----------------------*/ 
    const handleFileChange = (e) => {
      setAllowImport(false)
      const file = e.target.files[0]
      const extension = file.name.split('.').pop()
      const readFile = async (file) => {
        if(file){
          if(extension === 'csv'){
            Papa.parse(file, {
              skipEmptyLines:true,
              complete: function(results){
                if(results.errors?.length > 0){
                  toast({
                    title: 'Parsing Error',
                    description: 'There was an error parsing the scripted answer file.' ,
                    status: 'error',
                    duration: 5000,
                    position: 'top-right',
                    isClosable: true,
                  })
                  setAllowImport(true)
                }
                 
                else if(!(results.meta.fields.includes('custom_questions') && results.meta.fields.includes('custom_answers') && results.meta.fields.includes('properties'))){
                  setAllowImport(true)
                  toast({
                    title: 'Format Error',
                    description: 'Uploaded file was not a valid scripted answer file. Please upload a valid scripted answer file.' ,
                    status: 'error',
                    duration: 5000,
                    position: 'top-right',
                    isClosable: true,
                  })
                }
                else {
                  const csvData = results.data.map(item=>[item.custom_questions,item.custom_answers,item.properties])
                  uploadScriptedAnswers(csvData)
                }
              },
              header: true, 
            })
          }else{
             const data = await file.arrayBuffer()
              const workbook = read(data)
              const json_data = utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1,blankrows:false })
              const validate = validateExcelData(json_data)
              if(!validate.status){
                if(validate?.message){
                  setAllowImport(true)
                  toast({
                      title: 'Format Error',
                      description: `${validate?.message}`,
                      status: 'error',
                      duration: 5000,
                      position: 'top-right',
                      isClosable: true,
                  }) 
                }
              }
              else{
                uploadScriptedAnswers(json_data.slice(1))
              }
          } 
        }
      }
      readFile(file)
      importRef.current.value=''
    }
    /** ----------------------------- End Handle Clicking on Import Scripted Answers button --------------  */

    /** ----------On Selecting an option from the Article filter drop-down  ---------- */
    const handleMultiSelectChange = (value) =>{
        setFilterRecords(value)
        setCurrentPage(1)
    }
    
    /**  ------------------------------Pagination---------------------------------- */

    const handleItemsPerPage=(e)=>{
     setItemsPerPage(e.target.value)
     setCurrentPage(1)
    }
    const handlePrev=()=>{
      if(currentPage===1){
        setCurrentPage(1)
      }
      else{
        setCurrentPage(currentPage - 1)
      }
    }
    const handleNext=()=>{
     if(currentPage>pages[pagesLength-1]){
      setCurrentPage(pages[pagesLength-1])
      }
      else{
        setCurrentPage(currentPage+1)
      }
    }
    const handlePageNumber=(e)=>{
    setCurrentPage(e)
    }
  /**  ---------------------------------End Pagination------------------------- */
  const isMultiStepContent = (content,description) =>{
    const chunks = content?.split('<hr>') ?? []
    if(chunks.length >= 2){
        const transformedChunks = chunks.map((chunk,index)=>{
            return [extractWordsFromHtml(chunk.replace(/<button.*?<\/button>/g, '')?.trim())?.trim() === ''? '':`${index !== 0?' ' :''}Step ${index+1}. `+extractWordsFromHtml(chunk.replace(/<button.*?<\/button>/g, '')?.trim())]
        })
        return transformedChunks
    }
    return description 
  }
  /** --------------------------------End Event Handlers -------------------------------- */
  const dropdownRef = useRef(null)
  const toggleDropdown = () => {
    setIsBoxOpen((prev) => !prev)
  }
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsBoxOpen(false)
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  
    return (
      <Stack paddingBottom="22px">
        {!allowImport && 
          <Progress position="fixed" top="2" right="4" height='10px' width='50%' zIndex="9999" isIndeterminate/>
        }
        <Flex justify="space-between" padding='0px 0px 15px 0px' alignItems="center" borderBottom="1px solid #d1d5db">
          <Flex gap='10px' alignItems='center'>
            <Text className="page-title" data-testid='ka_table_heading'>Knowledge Articles</Text>
            {/* <Tooltip placement='right' bg='transparent' color='#000' boxShadow='none'
                label={
                <Flex fontSize='14px' p='10px' gap='10px' alignItems='flex-start' w='331px' bg='#fff' borderRadius='6px' boxShadow='0px 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)' >
                    <HiOutlineInformationCircle size='24px' color="#E79B04"/>
                    <Text as='span' data-testid='ka_table_heading_tooltip'>Articles restricted to TeamSpaces would not be listed here.</Text> 
                </Flex>
                }
            >
                <span><HiOutlineInformationCircle color='#94A3B8' size='24px'/></span>   
            </Tooltip> */}
          </Flex>
            <Flex alignItems={'center'} gap='10px'>
              <Box><InputGroup  maxW='285px'  data-testid='ka_table_search'>
                  <Input placeholder="Search" border="1px solid #D1D5DB" height="36px" value={searchQuery} onChange={(e)=> {
                    setCurrentPage(1)
                    setSearchQuery(e.target.value)
                    }} />
                  <InputRightElement height="36px" pointerEvents="none" children={<FiSearch size="20px" color="#94A3B8"/>} />
              </InputGroup></Box>
              <Box data-testid={'ka_table_filter'}>
                  <MultiSelect 
                   _focus={{ ring: '0' }}
                      name='filterStatus' 
                      isMulti={false}
                      options={[{label:'All Articles',value:'all'},{label:'Draft', value:'draft'}]} 
                      onChange={handleMultiSelectChange}
                      dropdownHandleRenderer={() => (
                        // <HiOutlineFunnel/>
                        <ChevronDownIcon/>
                      )}
                      // onChange={handleInputChange} 
                      value={filterRecords} 
                      // placeholder='Select attribute'
                      style={{ height: '36px', padding:'16px 15px 16px 17px', color:'#6B7280', fontSize:'14px', lineHeight:"normal", borderRadius:'6px', fontWeight:"500", border:'1px solid #D1D5DB',width:'220px' }}
                  />
              </Box>
              <Box data-testid='ka_table_import_answers' h="36px">
                {/* <Input style={{display:"none"}} placeholder="Import Scripted Answers" type='file' hidden accept=".csv, .xls, .xlsx" ref={importRef} onChange={handleFileChange} />
                <Button height="36px" fontSize="14px" className="secondaryButton" variant='outline' isDisabled={!allowImport || workspaceMetadata==='noWorkSpace'} leftIcon={<FiUpload size='20px' color='#94A3B8'/>} onClick={()=>importRef.current.click()}><Text paddingTop="2px">Import Scripted Answers</Text></Button> */}
              <Box position={"relative"} ref={dropdownRef}>
                <Box onClick={toggleDropdown} width='250px' height={'36px'} px="8px" py="4px" border="1px solid #d1d5db" borderRadius="5px" cursor="pointer">
                  <Flex justifyContent={'space-between'} alignItems={'center'}>
                    <Text textAlign="center" className="ImportKnowledge" fontSize={'14px'}>Import Knowledge Articles</Text>
                    <ChevronDownIcon />
                  </Flex>
                </Box>
                <Box display={isBoxOpen ? "block" : "none"} zIndex={1} transition={"all 0.8s ease"} position={'absolute'} right={0} mt={'12px'} background={'#ffffff'} width='250px' border="none" outline="none" _focus="none" marginTop={'6px'} boxShadow="0 -4px 6px rgba(0, 0, 0, 0.1)"  borderRadius="md" alignItems='flex-start' justifyContent='space-between' overflow='hidden' max-height='300px'>
                  <Box p="10px" w="100%" margin-top="3px">
                    <Flex direction="column" w="100%" max-height='300px' overflowY="auto" className='custom-scroll-gray' onClick={toggleDropdown}>
                      <Flex
                        alignItems='center'
                        justifyContent={'center'}
                        _hover={{ bg: 'rgba(209, 213, 219, 0.3)' }}
                        isDisabled={!allowImport || workspaceMetadata==='noWorkSpace'}  onClick={()=>importRef.current.click()}
                      >
                        <Flex alignItems="center" width="24px" height="24px" color='#94A3B8'>
                          <HiArrowUpTray />
                        </Flex>
                        <Flex justifyContent="space-between" width="100%">
                          <Text
                            color="#6B7280"
                            fontSize="14px"
                            fontFamily="Inter"
                            fontWeight="500"
                            textAlign="center"
                          >
                          <Input style={{display:"none"}} placeholder="Import Scripted Answers" type='file' hidden accept=".csv, .xls, .xlsx" ref={importRef} onChange={handleFileChange} />
                            Import Knowledge Articles
                          </Text>
                        </Flex>
                      </Flex>
                      <Flex
                        alignItems="center"
                        paddingY="5px"
                        _hover={{ bg: 'rgba(209, 213, 219, 0.3)' }}
                      >
                        <Flex alignItems="center" width="24px" height="24px" color='#94A3B8'>
                          <HiArrowDownTray />
                        </Flex >
                        <Flex justifyContent="space-between" width="100%">
                          <Text
                            color="#6B7280"
                            fontSize="14px"
                            fontFamily="Inter"
                            fontWeight="500"
                            textAlign="center"
                            onClick={downloadTemplate}
                          >
                            Download Format Sample
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Box>
                </Box>
              </Box>              </Box>
              <Box display="flex" flexWrap="nowrap">
              <Button 
               _disabled={{
                 _hover:{backgroundColor:"#E5E7EB",color:"#8993A1"},
                 backgroundColor:"#E5E7EB",
                 color:"#8993A1",
                 cursor:"not-allowed"
               }}
               _hover={{
                 backgroundColor:"#0047EB"
               }}
                color="white"
                backgroundColor="#2563EB"
                fontWeight="500"
                h="36px"
                padding="9px 17px"
                fontSize="14px"
                onClick={handleCreateArticle} data-testid='ka_table_create_button' isDisabled={workspaceMetadata==='noWorkSpace'}
                title={workspaceMetadata==='noWorkSpace'?'Please select valid workspace':''}><Text paddingTop="2px">Create New Article</Text> </Button>
  </Box>
            </Flex>
        </Flex>
        <Box>
          {
            globalState.isFetching ?
              ( 
                <Flex direction="column" justifyContent='center' alignItems='center' >
                    <Spinner
                        thickness='4px'
                        speed='0.65s'
                        emptyColor='gray.200'
                        color='#2ac769'
                        size='xl'
                        data-testid='ka_table_fetching_articles_spinner'
                    />
                    <Text my='2' data-testid='ka_table_fetching_articles_text'>Fetching Articles ...</Text>
                </Flex>
              ):
            records.length===0 ? 
              (
                <Flex flexDir="column" justifyContent='center' alignItems='center' data-testid='ka_table_no_articles_container'>
                    <Image width='140px' height='140px' src={norecord} alt='no records' data-testid='ka_table_no_articles_image'/>
                    <Text as='h5' fontSize='18px' fontWeight='bolder' my='20px' textAlign='center' data-testid='ka_table_no_articles_title' >No articles found!</Text>
                    <Text data-testid='ka_table_no_articles_text'>You can add new Articles by clicking on the Create New Article button on the top right.</Text>
                </Flex>
            
            ):
            totalRecordsToBeDisplayed.length===0 ?
             (
              <Flex flexDir="column" justifyContent='center' alignItems='center' data-testid='ka_table_no_articles_container'>
                    <Image width='140px' height='140px' src={norecord} alt='no records' data-testid='ka_table_no_articles_image'/>
                    <Text as='h5' fontSize='18px' fontWeight='bolder' my='20px' textAlign='center' data-testid='ka_table_no_articles_title' >No articles found!</Text>
                    <Text data-testid='ka_table_no_articles_text'>Please retry with a different search query or filter option.</Text>
                </Flex>
             ):
           currentPageRecords.map((item,index)=>{
            const articleDetail = item
            const data = item.articleData
            const formatDate = (date) => {
              if (!date) return ''
              const options = { day: '2-digit', month: 'short', year: 'numeric', hour: 'numeric', minute: '2-digit', hour12: true }
              return new Date(date).toLocaleDateString('en-GB', options).replace(/(am|pm)/g, match => match.toUpperCase())
            }
            return(
              <Box key={index}  borderBottom={'1px solid #D1D5DB'} data-testid={`article_container_${index}`}>
                <Flex direction={'column'} justifyContent={"space-between"}>
                  <Box>
                    <Flex py={'10px'} alignItems={'center'} justifyContent={'space-between'}>
                      <Flex alignItems={'center'} gap={'2'}>
                        <Text fontSize='14px' lineHeight={'16.94px'} cursor='pointer' fontWeight='500' color='#000' onClick={()=>handleArticleDetail(item.id)} data-testid='ka_table_article_title'>{data.title}</Text>
                        {data.scripted &&  
                        <Flex gap='4px' alignItems='center' justify='center' padding='2px 5px' borderRadius='100px' border='1px solid #8463F9'>
                          <Text as='span' fontSize="12px" fontWeight='500' lineHeight={'14.52px'} color='#8463F9' data-testid='ka_table_article_scripted'>Scripted Answer</Text>
                        </Flex>}
                        {data.resolution_note &&
                        <Flex gap='4px' alignItems='center' justify='center' padding='2px 5px' borderRadius='100px' border='1px solid #229F54'>
                          <Text as='span' fontSize="12px" fontWeight='500' lineHeight={'14.52px'} color='#229F54' data-testid='ka_table_article_resolution'>Resolution Note</Text>
                        </Flex>}
                        {data.restrictAgents &&
                        <Flex gap='4px' alignItems='center' justify='center' padding='2px 5px' borderRadius='100px' border='1px solid #E79B04'>
                          <Text as='span' fontSize="12px" fontWeight='500' lineHeight={'14.52px'} color='#E79B04' data-testid='ka_table_article_agent'>Agent note</Text>
                        </Flex>}
                      </Flex>
                      <Flex gap={2} alignItems={'center'}>
                        {(data.views !==null &&  data.views !== undefined && data.views !==0) &&
                          <Tooltip placement='bottom-start' data-testid='ka_table_article_view_tooltip' label={`was referred ${data.views} times in bot conversations`} fontSize='12px' bg='#fff' color='#000' borderRadius='5px' boxShadow='0px 4px 15px 0px rgba(0, 0, 0, 0.10)'>
                          <Flex padding='2px 5px' h='19px' gap='4px' borderRadius='2px' border='1px solid #2563EB' color='#2563EB' alignItems='center'>
                            <MdOutlineRemoveRedEye size='15px' data-testid='ka_table_view_icon'/>
                            <Text as='span' fontSize='12px' fontWeight='bolder' data-testid='ka_table_article_view_data'>{data.views}</Text>
                          </Flex>
                        </Tooltip>}
                        {(data.rating !==null &&  data.rating !== undefined && data.rating !==0) &&
                            <Flex padding='2px 10px' h='19px' gap='5px' borderRadius='2px' border='1px solid #E79B04' color='#E79B04' alignItems='center'>
                            <MdOutlineStarBorder size='12px' data-testid='ka_table_rating_icon'/>
                            <Text as='span' fontSize='12px' fontWeight='bolder' data-testid='ka_table_article_rating_data'>{data.rating}/5</Text>
                          </Flex>} 
                    </Flex>
                  </Flex>
                  <Flex py={'5px'}>
                      <Text fontFamily={'Inter'} maxH={'34px'} width={'85%'} noOfLines={'2'} fontSize={'14px'} fontWeight={'400'} lineHeight={'16.94px'} textAlign={'left'} color={'#6B7280'} data-testid='ka_table_description'> {isMultiStepContent(data.content,data.description)}</Text>
                    </Flex>
                    <Flex>             
                      {data.status.length > 0 && data.status[0].label==='Draft' && <Text as='span' padding='1px 5px' borderRadius='4px' border='1px solid #E79B04' fontSize='12px' fontWeight='500' color='#E79B04'>{data.status[0].label}</Text>}
                    </Flex>
                  </Box>
                  <Box>
                    <Flex pt={'5px'} pb={'10px'} justifyContent={'space-between'} {...((!(data?.audience?.Whitelist && data?.audience?.Whitelist?.length !== 0) && !(data?.audience?.Blacklist && data?.audience?.Blacklist?.length !== 0))?{style:{alignItems:'center'}}:{style:{alignItems:'flex-end'}})}>
                      <Box {...(!(data?.audience?.Whitelist && data?.audience?.Whitelist?.length !== 0) && !(data?.audience?.Blacklist && data?.audience?.Blacklist?.length !== 0) ? { style: {display:'flex',alignItems:'center'} } : {})} >
                        <Text fontSize='14px' color='#9fa4a7' data-testid='ka_table_timestamp' {...((data?.audience?.Whitelist && data?.audience?.Whitelist?.length !== 0) || (data?.audience?.Blacklist && data?.audience?.Blacklist?.length !== 0) ? { style: {padding:'10px 0px'} } : {})}>Created at: {formatDate(articleDetail?.createdAt)} | Created by: { articleDetail?.createdBy?.split('@')[0]?.replace(/\./g, ' ')?.trim() ?? articleDetail?.createdBy} | Last updated at: {formatDate(articleDetail?.updatedAt)} | Last updated by: { articleDetail?.updatedBy?.split('@')[0]?.replace(/\./g, ' ')?.trim() ?? articleDetail?.updatedBy}</Text>
                        <Flex gap={2} py={'5px'}>
                          {data?.audience?.Whitelist && data?.audience?.Whitelist?.length !== 0 && 
                          <Flex flexShrink={0}>
                            <Text fontSize={'12px'} fontWeight={'500'} lineHeight={'16.94px'} color={'#6B7280'} >Whitelist Audience :</Text>
                          </Flex>}
                          {data?.audience?.Whitelist && data?.audience?.Whitelist?.length !== 0 && 
                          <Flex flexWrap={'wrap'} gap={2}>
                            {data?.audience?.Whitelist?.map((list,index)=>{
                              return <Box key={index} bgColor={'#DEE8FC'} color={'#2563EB'} borderRadius={'5px'} px={'4px'}>
                                <Text fontSize={'12px'} fontWeight={'500'} lineHeight={'16.94px'} py={'2px'} px={'3px'}>{list}</Text>
                              </Box>
                            })}
                          </Flex>}
                          {data?.audience?.Blacklist && data?.audience?.Blacklist?.length !== 0 && <Flex flexShrink={0}><Text fontSize={'12px'} fontWeight={'500'} lineHeight={'16.94px'} color={'#6B7280'} >Blacklist Audience :</Text></Flex>}
                          {data?.audience?.Blacklist && data?.audience?.Blacklist?.length !== 0 && <Flex flexWrap={'wrap'} gap={2}>
                          {data?.audience?.Blacklist?.map((list,index)=>{
                            return <Box key={index} bgColor={'#DEE8FC'} color={'#2563EB'} borderRadius={'5px'} px={'4px'}>
                              <Text fontSize={'12px'} fontWeight={'500'} lineHeight={'16.94px'} py={'2px'} px={'3px'}>{list}</Text>
                          </Box>
                          })}
                        </Flex>}
                        </Flex>
                      </Box>
                      <Flex gap={2} paddingBottom={'2px'}>
                        <Tooltip placement='top' label='Edit Article'><span><FiEdit size='24px' color='#94A3B8' onClick={()=>{workspaceMetadata!=='noWorkSpace' && handleEditArticle(item.id)}} data-testid='ka_table_article_edit_button' cursor='pointer'/></span></Tooltip>
                        <Tooltip placement='top' label='Delete Article'><span><HiOutlineTrash size='24px' color='#94A3B8' onClick={()=>handleArticleDelete(item.id,data.id,((data.status[0].label==='Published' && data?.resolution_note!==true)?true:false))} data-testid='ka_table_article_delete_button' cursor='pointer'/></span></Tooltip>
                      </Flex>
                      </Flex>
                  </Box>
                </Flex>
              </Box>  
            )
            })
          }
          </Box>
        <Box display='flex' justifyContent='space-between' width='100%' my='5' >
          <Box display='flex' alignItems='center' >
              Show
              <Box border='1px solid #e2e8f0' borderRadius='5px' mx='3' p='5px 10x' >
              <select onChange={handleItemsPerPage} style={{borderRadius:'5px',height:'30px'}}>
                  <option value='25'>25</option>
                  <option value='50' >50</option>
              </select>
              </Box>
              articles per page
          </Box>
          <Pagination totalPages={pagesLength} currentPage={currentPage} onPageChange={handlePageNumber} />
        </Box>
      </Stack> 
    )
  }

  export default KnowledgeArticlesTable