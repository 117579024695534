import {HiOutlineWrench,HiOutlineTicket,HiOutlineUserGroup} from 'react-icons/hi2'
import { TfiComments } from "react-icons/tfi"
import { MdOutlineSupportAgent } from "react-icons/md"
import { BsSliders } from "react-icons/bs"
import { HiOutlineBookOpen } from "react-icons/hi2"
import { GrDocumentUpdate, GrDocumentPerformance } from "react-icons/gr"
import {RiListSettingsFill} from 'react-icons/ri'
import {SlGraph,SlSpeedometer} from 'react-icons/sl'
export const sidebarData=[
    {
        path: '/botmanagement/dashboard',
        exact: true,
        isNav: true,
        name: 'Virtual Agent Management',
        label: 'Virtual Agent Management',
        icon: <HiOutlineUserGroup/>,
        allowedRole: ['bot_analyst', 'bot_admin', 'tenant_admin', 'botstudio_user'],
    },
    {
        exact: true,
        isNav: true,
        name: 'Knowledge Management',
        label: 'Knowledge Management',
        allowedRole: ['bot_analyst', 'bot_admin', 'botstudio_user'],
        icon: 'fa fa-cubes',
        icon: <HiOutlineBookOpen />,
        id:2,
        subMenuItems:[
            {
                exact: true,
                isNav: true,
                isActive:true,
                name: 'Manage Knowledge',
                label: 'Manage Knowledge',
                allowedRole: ['aidochub'],
                icon: <GrDocumentUpdate />,
                childMenuItems:[
                    {
                        path: '/aiconsole/documents',
                        exact: true,
                        isNav: true,
                        isActive:true,
                        name: 'Documents',
                        label: 'Documents',
                        allowedRole: ['aidochub'],
                    },
                    {
                        path: '/aiconsole/sharepoint',
                        exact: true,
                        isNav: true,
                        isActive:false,
                        name: 'SharePoint',
                        label: 'SharePoint',
                        allowedRole: ['aidochub'],
                    },
                    {
                        path: '/aiconsole/urls',
                        exact: true,
                        isNav: true,
                        isActive:false,
                        name: 'External Websites',
                        label: 'External Websites',
                        allowedRole: ['aidochub'],
                    },
                    {
                        path: '/aiconsole/articles',
                        exact: true,
                        isNav: true,
                        isActive:false,
                        name: 'Knowledge Articles',
                        label: 'Knowledge Articles',
                        allowedRole: ['aidochub'],
                    },
                ]
            },
            {
                exact: true,
                isNav: true,
                isActive:true,
                name: 'Knowledge Insights',
                label: 'Knowledge Insights',
                allowedRole: ['aidochub'],
                icon: <GrDocumentPerformance />,
                childMenuItems:[
                    {
                        path: '/aiconsole/gaps',
                        exact: true,
                        isNav: true,
                        isActive:false,
                        name: 'Knowledge Gaps',
                        label: 'Knowledge Gaps',
                        allowedRole: ['aidochub'],
                    }
                ]
            },
            
        ]
    },
    // {
    //     path: '/catalog/template-cardlist',
    //     exact: true,
    //     isNav: true,
    //     name: 'Service catalog',
    //     label: 'Service Catalog',
    //     icon: <HiOutlineWrench />,
    //     allowedRole: ['asc_admin'],
    // },
    {
        path: '/agent/inbox',
        exact: true,
        isNav: true,
        name: 'Ticket Management',
        label: 'tickets',
        icon: <HiOutlineTicket />,
        allowedRole: ['ticket_user', 'ticket_agent', 'ticket_agent_supervisor', 'ticket_admin'],
    },
    {
        path: '/livechat/my-chats',
        exact: true,
        isNav: true,
        name: 'Live Chat',
        label: 'Live Chat',
        icon: <TfiComments />,
        allowedRole: ['chat_agent', 'chat_agent_admin', 'chat_agent_supervisor'],
    },
    // {
    //     path: '/learning/program',
    //     exact: true,
    //     isNav: true,
    //     name: 'Learning Mgmt',
    //     label: 'learningApp',
    //     icon: 'fa fa-black-tie',
    //     allowedRole: ['content_author', 'coach'],
    // },
    {
        path: '/user-management',
        exact: true,
        isNav: true,
        name: 'Admin App',
        label: 'Admin App',
        icon: <BsSliders/>,
        allowedRole: ['user_admin', 'tenant_admin'],
    },
    {
        path: '/virtualagent',
        exact: true,
        isNav: true,
        name: 'Virtual Agent',
        label: 'Virtual Agent',
        icon: <MdOutlineSupportAgent />,
        allowedRole: ['bot_user'],
    }
]